import {
    COLLECTIONS_INVALIDATE, COLLECTIONS_FETCH_REQUEST, COLLECTIONS_FETCH_SUCCESS, COLLECTIONS_FETCH_FAILURE,
} from '../actions/collections';

const initialState = {
    isFetching: false,
    didInvalidate: false,
    isLoaded: false,
    byKey: null, /* key => {
        key: string,
        name: string,
        shortName: string,
        rootFolderId: string} */
    allKeys: null,
    error: null
}

const collections = (state = initialState, action) => {
    switch (action.type) {
        case COLLECTIONS_FETCH_REQUEST:
            return { ...state, isFetching: true };
        case COLLECTIONS_FETCH_SUCCESS:
            return {
                ...state,
                isFetching: false,
                didInvalidate: false,
                isLoaded: true,
                byKey: action.byKey,
                allKeys: action.allKeys
            }
        case COLLECTIONS_FETCH_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case COLLECTIONS_INVALIDATE:
            return {
                ...state,
                didInvalidate: true
            }
        default:
            return state
    }
}

export default collections