/* API key for 'MIT Documentation' project */
const config = {
    gapi: {
        apiKey: 'AIzaSyCajSt1tKP3x-IwkC6p_pQKa6dWCSk_nbo',
        clientId: '881573275821-t3ppe9o1rra1psmq4b36uudbgqik85fn.apps.googleusercontent.com',
        discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
        scope: 'https://www.googleapis.com/auth/drive'
    },
    scope: {
        corpora: 'drive',
        driveId: '0AIQsFDRxvuesUk9PVA'
    },
    collections: [
        {
            key: 'sga', rootFolderId: '131WP2odpBTHVpmJODdWI49c9_JMD08RF', 
            shortName: 'SG&A', name: 'Sales, General & Admin'
        },
        {
            key: 'shop', rootFolderId: '1bCNASSuMKhx1KRX7Wk8jHzUazTdXMS1f', name: 'Production'
        },
        {
            key: 'mit', rootFolderId: '1mVxe2u_2di3EheVFkmY3KtZVFKF-JgNL', 
            shortName: 'Jobs', name: 'Job Descriptions'
        },
    ],
    templates: {
        sop: '1aKtjwiBtFl0Hw1_OnIBrT0vIEMcohjORfnViLNEpEz4',
        ref: '12fTKidWSu6TwBreUrJvg40GEOscl2T2enJbnk5VPc30',
    },
    flags: {
        folder: '[Maddox-Wiki]',
        exclude: '[Maddox-Wiki-Exclude]',
        url: '[Wiki-URL=<URL>]'
    },
    newPageUrl: 'https://docs.google.com/forms/d/e/1FAIpQLScI7S0Jzxz4oaglYj4MjfIxu67xfSs8hGx6LXaLsN1sBr-5MQ/viewform?usp=pp_url&entry.1188559154=<PAGE_TYPE>&entry.1117313825=<FOLDER_URL>',
};

export default config;