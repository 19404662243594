import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux'

import useNavigation, { PENDING, INVALID } from './useNavigation';

const useSelectedCollection = () => {
    const collections = useSelector(s => s.collections);
    const nav = useNavigation();

    const history = useHistory();
    const selectCollection = (key) => {
        if (nav.collectionKey && nav.collectionKey === key) return;
        history.push(`/${key}`);
    }

    const selectedCollection = (() => {
        if (nav.route === PENDING || nav.collectionKey === PENDING)
            return {
                isLoading: true,
                key: null,
                name: null,
                shortName: null,
                rootFolderId: null,
            };

        if (!nav.collectionKey || nav.collectionKey === INVALID)
            return {
                isLoading: false,
                key: null,
                name: null,
                shortName: null,
                rootFolderId: null,
            };

        const selectedCollection = collections.byKey[nav.collectionKey];
        return {
            isLoading: false,
            key: nav.collectionKey,
            name: selectedCollection.name,
            shortName: selectedCollection.shortName ? selectedCollection.shortName : selectedCollection.name,
            rootFolderId: selectedCollection.rootFolderId,
        };
    })();

    return { selectedCollection, selectCollection };
}

export default useSelectedCollection;