import React from 'react';
import { Helmet } from 'react-helmet-async';

import useSelectedCollection from '../hooks/useSelectedCollection';
import Spinner from '../components/Spinner';
import useNavigation, { COLLECTION } from '../hooks/useNavigation';
import useAuthorization from '../hooks/useAuthorization';
import { Link } from 'react-router-dom';
import useDocuments from '../hooks/useDocuments';
import useFolders from '../hooks/useFolders';

const DocumentList = () => {
    const auth = useAuthorization();
    const nav = useNavigation();
    const folders = useFolders();
    const documents = useDocuments();
    const { selectedCollection } = useSelectedCollection();

    if (nav.route !== COLLECTION || !auth.isLoggedIn) return null;

    if (selectedCollection.isLoading || folders.areLoading || !documents.areLoaded)
        return (
            <div className='centering-container'>
                <Spinner color='gray' />
            </div>
        );

    const getFolders = (folderId) => folderId in folders.byParentId
        ? folders.byParentId[folderId].map(id => ({ ...folders.byId[id], type: 'folder' }))
        : [];
    const getDocuments = (folderId) => folderId in documents.byParentId
        ? documents.byParentId[folderId].map(id => ({ ...documents.byId[id], type: 'document' }))
        : [];
    const getChildren = (folderId) => [...getFolders(folderId), ...getDocuments(folderId)];
    const renderChildren = (id) => (
        <ul>
            {getChildren(id).map(c => c.type === 'folder'
                ? <li key={c.id}>{c.name}{renderChildren(c.id)}</li>
                : <li key={c.id}><Link to={`/${c.id}`}>{c.name}</Link></li>)}
        </ul>
    )

    return (
        <div className='content'>
            <Helmet><title>{selectedCollection.name}</title></Helmet>
            <h3 className='title is-3'>{selectedCollection.name}</h3>
            {renderChildren(selectedCollection.rootFolderId)}
        </div>);
}

export default DocumentList;