import React from 'react';
import parse, { domToReact } from 'html-react-parser';

// rewriteLinks: (url) => {rewrite: bool, path: string, url: string}
const parseFile = (file, rewriteLinks, history) => {
    return {
        style: (() => {
            let isStyleTagPresent = false;
            let options = {
                replace: domNode => {
                    if (domNode.name === 'html') {
                        let body = domNode.children.find(c => c.name === 'head');
                        if (!body) return;
                        let style = body.children.find(c => c.name === 'style');
                        if (!style) return;
                        isStyleTagPresent = true;
                        //console.log(style);
                        return <>{domToReact(style.children/*, options*/)}</>;
                    }
                }
            };
            let parsed = parse(file, options);
            return isStyleTagPresent ? parsed : null;
        })(),
        body: (() => {
            let options = {
                replace: domNode => {
                    if (domNode.name === 'html') {
                        let body = domNode.children.find(c => c.name === 'body');
                        return <>{domToReact(body.children, options)}</>;
                    }
                    if (domNode.name !== 'a') return;
                    if (!domNode.attribs.href) return;
                    let { rewrite, path, href } = rewriteLinks(domNode.attribs.href);
                    if (!rewrite) return;
                    if (path) {
                        domNode.attribs.href = path;
                        domNode.attribs.onClick = (e) => { e.preventDefault(); history.push(path); }
                        return domToReact([domNode]);
                    }
                    if (href) {
                        domNode.attribs.href = href;
                        return domNode;
                    }
                    // let link = parseGoogleDriveLink(domNode);
                    // if (link.result) {
                    //     const newRoute = rewriteLinks(link.id);
                    //     domNode.attribs.href = newRoute;
                    //     domNode.attribs.onClick = (e) => { e.preventDefault(); history.push(newRoute); }
                    //     return domToReact([domNode]);
                    // }
                    // link = parseGoogleRedirectLink(domNode);
                    // if (link.result) {
                    //     domNode.attribs.href = link.url;
                    //     return domNode;
                    // }
                }
            };
            let parsed = parse(file, options);
            return parsed;
        })(),
    }
}

export default parseFile;
