import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'

import { openManyIfNeeded } from '../actions/treeState';
import useNavigation, { INVALID } from '../hooks/useNavigation';
import useFolders from '../hooks/useFolders';
import useDocuments from '../hooks/useDocuments';

const DocumentTreeInitializer = () => {
    const dispatch = useDispatch();

    const nav = useNavigation();
    const folders = useFolders();
    const documents = useDocuments();

    const history = useHistory();

    const invalidCollection = nav.collectionKey === INVALID;
    useEffect(() => {
        if (invalidCollection) {
            if (nav.documentIds)
                console.log(`Multiple files found for '${nav.documentLoc}'.`);
            else
                console.log(`File '${nav.documentLoc}' not found in any collection'.`);
            history.push('/');
        }
        else {
            if (nav.documentUrl && (nav.documentLoc === nav.documentId || nav.documentLoc !== nav.documentUrl))
                history.push(`/${nav.documentUrl}`);
        }
    }, [invalidCollection, nav.documentLoc, nav.documentId, nav.documentUrl, history, documents, nav.documentIds])

    useEffect(() => {
        if (!invalidCollection && nav.documentId && documents.areLoaded) {
            const parents = findAllParents(folders, documents, nav.documentId);
            dispatch(openManyIfNeeded(parents));
        }
    }, [invalidCollection, nav.documentId, documents.areLoaded, folders, documents, dispatch])

    return null;
}

const findAllParents = (folders, documents, fileId) => {
    let parentId = documents.byId[fileId].parentId;
    const parents = [];
    do {
        parents.push(parentId);
        parentId = parentId in folders.byId ? folders.byId[parentId].parentId : null;
    }
    while (parentId)
    return parents;
}

export default DocumentTreeInitializer;