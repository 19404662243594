export const FOLDER_TOGGLE = 'FOLDER_TOGGLE'
export const FOLDER_OPEN_MANY = 'FOLDER_OPEN_MANY'

export const toggle = (folderId) => ({
    type: FOLDER_TOGGLE,
    folderId
})

export const openMany = (folderIds) => ({
    type: FOLDER_OPEN_MANY,
    folderIds
})

export const openManyIfNeeded = (folderIds) =>
    (dispatch, getState) => {
        const state = getState().treeState;
        const toOpen = folderIds.filter(id => !state[id]);
        if (toOpen.length === 0)
            return Promise.resolve();
        return dispatch(openMany(toOpen));
    }
