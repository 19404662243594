import { filter, switchMap, startWith, map, take, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';

import config from '../config'; // hardcoded collection set

export const COLLECTIONS_FETCH_REQUEST = 'COLLECTIONS_FETCH_REQUEST'
export const COLLECTIONS_FETCH_SUCCESS = 'COLLECTIONS_FETCH_SUCCESS'
export const COLLECTIONS_FETCH_FAILURE = 'COLLECTIONS_FETCH_FAILURE'
export const COLLECTIONS_INVALIDATE = 'COLLECTIONS_INVALIDATE'

const request = () => dispatch => {
    // this is hardcoded right now but will be easy to switch to an API call later
    try {
        dispatch({ type: COLLECTIONS_FETCH_REQUEST });
        const collections = config.collections;
        const byKey = collections.reduce((map, c) => {
            map[c.key] = c;
            return map;
        }, {});
        const allKeys = collections.reduce((map, c) => {
            map.push(c.key);
            return map;
        }, []);
        return dispatch(fetchSuccess(byKey, allKeys));
    }
    catch (error) {
        return dispatch(fetchFailure(error));
    }
}
const fetchSuccess = (byKey, allKeys) => ({
    type: COLLECTIONS_FETCH_SUCCESS,
    byKey,
    allKeys
})
const fetchFailure = (error) => ({
    type: COLLECTIONS_FETCH_FAILURE,
    error
})
export const invalidate = () => ({
    type: COLLECTIONS_INVALIDATE
})

export const shouldFetch = (state) => {
    const collections = state.collections;
    if (collections.isFetching)
        return false
    if (!collections.isLoaded)
        return true
    else
        return collections.didInvalidate
}

export const getRootFolderId$ = (key, state$) => of(key).pipe(
    withLatestFrom(state$),
    map(([key, state]) => state.collections.byKey[key].rootFolderId)
)

export const fetchIfNeeded$ = (action$, state$) =>
    of(shouldFetch(state$.value)).pipe(
        filter(v => v),
        switchMap(() =>
            action$.pipe(
                ofType(COLLECTIONS_FETCH_SUCCESS, COLLECTIONS_FETCH_FAILURE),
                take(1), // listen for the response to come back...
                ofType(COLLECTIONS_FETCH_FAILURE),
                map(() => { throw new Error('Collections could not be loaded.') }),
                startWith(request())
            )
        )
    )

export const fetchIfNeeded = () =>
    (dispatch, getState) => {
        if (!shouldFetch(getState()))
            return Promise.resolve();
        dispatch(request());
    }
