import config from '../config'

// based on RFC 3986 section 2.3 Unreserved Characters (January 2005)
// https://en.wikipedia.org/wiki/Percent-encoding
export const VALID_URL_CHARS = '[A-Za-z0-9_.~-]';
export const VALID_URL_PATTERN = new RegExp(`^(?<url>${VALID_URL_CHARS}+)$`)
export const PREFERRED_URL_CHARS = '[a-z0-9_.~-]';
export const PREFERRED_URL_PATTERN = new RegExp(`^(?<url>${PREFERRED_URL_CHARS}+)$`)

export const MAX_URL_LENGTH = 75;

const escapeRegExp = (string) =>
    string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

function getUrlPattern() {
    const parts = config.flags.url.split('<URL>');
    if (!parts.length === 2)
        throw new Error('config.flags.url not in the expected format');
    return new RegExp(`^(?<before>.*)${escapeRegExp(parts[0])}(?<url>${VALID_URL_CHARS}+)${escapeRegExp(parts[1])}(?<after>.*)$`);
}
export const URL_PATTERN = getUrlPattern();

export const getUrl = (description) => {
    const match = URL_PATTERN.exec(description);
    return match ? match.groups.url : undefined;
}

export const getUrlQueryWord = (url) => config.flags.url.replace('<URL>', url);

const isEmpty = (str) => str && str.trim() !== '' ? false : true;

export const updateUrl = (description, url) => {
    const tag = getUrlQueryWord(url);
    const match = URL_PATTERN.exec(description);
    if (!match)
        return `${tag}${isEmpty(description) ? '' : "\n" + description}`;
    return `${match.groups.before}${tag}${match.groups.after}`;
}

export const toUrl = (input) => {
    const pattern = new RegExp(VALID_URL_CHARS.slice(0, 1) + '^' + VALID_URL_CHARS.slice(1));
    const parts = input.toLowerCase().split(pattern).filter(p => !isEmpty(p));
    return parts.join('-');
}
