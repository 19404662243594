import React, { useState } from 'react';

import NavBar from './components/NavBar';
import DocumentTreeInitializer from './containers/DocumentTreeInitializer';
import DocumentTree from './containers/DocumentTree';
import DocumentViewer from './components/DocumentViewer';
import InitSpinner from './containers/InitSpinner';
import SignedOutWelcome from './components/SignedOutWelcome';
import CollectionList from './containers/CollectionList';
import DocumentList from './containers/DocumentList';
import SearchResults from './containers/SearchResults';
import RevisionsModal from './components/RevisionsModal';
import { AddDocumentModal } from './components/AddDocument';
import { EditDocumentModal } from './components/EditDocument';
import './style/App.scss';

export default function App(props) {
    const [showRevisions, setShowRevisions] = useState(false);
    return (
        <>
            <DocumentTreeInitializer />
            <header>
                <NavBar />
                <DocumentTree format='navbar' />
            </header>
            <div className='wrapper columns primary-wrapper'>
                <DocumentTree format='menu' />
                <main className="column main">
                    {/*
                <nav class="breadcrumb is-small" aria-label=breadcrumbs>
                    <ul>
                        <li><a href=#>Home</a></li>
                        <li class=is-active><a href=# aria-current=page>Dashboard</a></li>
                    </ul>
                </nav>
                */}
                    {/* <NavDisplay /> */}
                    <SignedOutWelcome />
                    <CollectionList />
                    <DocumentList />
                    <SearchResults />
                    <DocumentViewer onShowRevisions={() => setShowRevisions(true)} />
                </main>
            </div>
            <RevisionsModal onClose={() => setShowRevisions(false)} isOpen={showRevisions} />
            <AddDocumentModal />
            <EditDocumentModal />
            <InitSpinner />
        </>
    );
}
