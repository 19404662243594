import React, { forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames';
import Spinner from './Spinner';
import { AddDocumentButton } from './AddDocument';

import { SIGNED_OUT, NO_COLLECTION, LOADING } from '../containers/DocumentTreeFolder';
import { Link } from 'react-router-dom';

const DocumentMenuFolder = forwardRef(({
    show, error, isRoot, selectedCollectionLink, selectedCollectionName, link,
    folderName, folderId, showAddChildren, isExpanded, children, onClick }, ref) => {

    useImperativeHandle(ref, () => ({
        onSelect() {
            //console.log('onSelect!');
        }
    }));

    if (show === NO_COLLECTION || show === SIGNED_OUT)
        return (
            <aside className="column is-3 aside document-menu is-hidden-touch">
            </aside>);

    if (show === LOADING) {
        return (
            <aside className="column is-3 aside document-menu is-hidden-touch">
                <div className='centering-container'>
                    <Spinner />
                </div>
            </aside>);
    }

    // if (show === ERROR)
    //     return ShowRoot(error, null);

    if (isRoot) {
        return (
            <aside className="column is-3 aside document-menu is-hidden-touch">
                <Link to={selectedCollectionLink} className='menu-label'>{selectedCollectionName}</Link>
                <ul className='menu-list nav-menu'>{children}</ul>
            </aside>);
    }

    return (
        <li>
            <div className="level mb-0">
                <a className="level-item" href={link} onClick={e => { e.preventDefault(); onClick(); }}>{folderName}</a>
                {showAddChildren ? <AddDocumentButton folderId={folderId} /> : null}
            </div>
            <ul className={classNames({ 'is-hidden': !isExpanded, 'nav-menu': true })}>
                {children}
            </ul>
        </li>
    );
})

export default DocumentMenuFolder;
