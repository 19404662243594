import React from 'react';

import RevisionHistory from './RevisionHistory';

const RevisionModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    const close = (e) => {if (onClose) onClose()};

    return (
        <div className="modal is-active">
            <div className="modal-background" onMouseDown={close}></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Revision History</p>
                    <button className="delete" aria-label="close" onClick={close}></button>
                </header>
                <section className="modal-card-body">
                    <RevisionHistory />
                </section>
            </div>
        </div>
    );
}

export default RevisionModal;