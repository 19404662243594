import {
    AUTH_INIT_BEGIN, AUTH_INIT_SUCCESS, AUTH_INIT_FAILURE,
    AUTH_LOGIN_BEGIN, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAILURE,
    AUTH_LOGOUT_BEGIN, AUTH_LOGOUT_SUCCESS, AUTH_LOGOUT_FAILURE,
    AUTH_STATUS_UNINITIALIZED, AUTH_STATUS_INITIALIZING, AUTH_STATUS_INITIALIZED
} from '../actions/auth';

const initialState = {
    status: AUTH_STATUS_UNINITIALIZED,
    isInitialized: false,
    isLoggedIn: false,
    loggedInAs: null,
    error: null
}

const auth = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_INIT_BEGIN:
            return { ...state, status: AUTH_STATUS_INITIALIZING };
        case AUTH_INIT_SUCCESS:
            return {
                ...state,
                status: AUTH_STATUS_INITIALIZED,
                isInitialized: true,
                isLoggedIn: action.isLoggedIn,
                loggedInAs: action.isLoggedIn ? action.loggedInAs : null
            }
        case AUTH_INIT_FAILURE:
            return {
                ...state,
                status: AUTH_STATUS_UNINITIALIZED,
                isInitialized: false,
                error: action.error
            }
        case AUTH_LOGIN_BEGIN:
            return state;
        case AUTH_LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                loggedInAs: action.loggedInAs
            }
        case AUTH_LOGIN_FAILURE:
            return {
                ...state,
                status: AUTH_STATUS_UNINITIALIZED,
                error: action.error
            }
        case AUTH_LOGOUT_BEGIN:
            return state;
        case AUTH_LOGOUT_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
                loggedInAs: null
            }
        case AUTH_LOGOUT_FAILURE:
            return {
                ...state,
                status: AUTH_STATUS_UNINITIALIZED,
                error: action.error
            }
        default:
            return state
    }
}

export default auth