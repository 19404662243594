import { switchMap, withLatestFrom, map, catchError, filter, take } from 'rxjs/operators';
import { of, from, concat, defer } from 'rxjs';
import { ofType } from 'redux-observable';

import Google from '../util/Google';
import { fetchIfNeeded$ as fetchCollectionsIfNeeded$ } from './collections';
import { ensureLoggedIn$ } from './auth';

export const FOLDERS_REQUEST = 'FOLDERS_REQUEST'
export const FOLDERS_SUCCESS = 'FOLDERS_SUCCESS'
export const FOLDERS_FAILURE = 'FOLDERS_FAILURE'
export const FOLDERS_INVALIDATE = 'FOLDERS_INVALIDATE'

export const request = () => ({
    type: FOLDERS_REQUEST
})
const failure = (error) => {
    if (error instanceof Error)
        error = {
            message: error.message,
            stack: error.stack
        };
    return {
        type: FOLDERS_FAILURE,
        error
    };
}
export const invalidate = () => ({
    type: FOLDERS_INVALIDATE
})

const success = (allFolders, collections) => {
    try {
        console.log(`evaluating ${allFolders.length} folders`);

        const allById = allFolders.reduce((a, f) => { a[f.id] = f; return a }, {});
        const allByParentId = allFolders
            .filter(f => f.parents)
            .flatMap(f => f.parents.map(p => ({ parentId: p, childId: f.id })))
            .reduce((a, v) => {
                if (!(v.parentId in a)) a[v.parentId] = [];
                a[v.parentId].push(v.childId);
                return a;
            }, {});

        const authorizedCollections = collections.allKeys.map(key => collections.byKey[key])
            .filter(c => c.rootFolderId in allById && allById[c.rootFolderId].capabilities.canListChildren)
            .map(c => ({
                key: c.key,
                name: c.name,
                shortName: c.shortName ? c.shortName : c.name,
                rootFolderId: c.rootFolderId,
                rootFolderName: allById[c.rootFolderId]
            }));

        const collectionById = {};
        const record = (folderId, collection) => {
            if (folderId in collectionById) return; // prevent loops
            collectionById[folderId] = collection;
            if (folderId in allByParentId)
                allByParentId[folderId].forEach(c => record(c, collection));
        }
        authorizedCollections.forEach(c => record(c.rootFolderId, c.key));

        const allIds = Object.keys(collectionById);

        const byId = Object.entries(collectionById).reduce((a, [folderId, collectionKey]) => {
            a[folderId] = {
                id: folderId,
                name: allById[folderId].name,
                canAddChildren: allById[folderId].capabilities.canAddChildren,
                collection: collectionKey,
                parentId: allById[folderId].parents[0],
            }
            return a;
        }, {});

        const byParentId = Object.entries(byId).reduce((a, [folderId, folder]) => {
            if (!(folder.parentId in a))
                a[folder.parentId] = [];
            a[folder.parentId].push(folderId);
            return a;
        }, {});

        for (const pid in byParentId)
            byParentId[pid].sort((a, b) => ('' + byId[a].name).localeCompare(byId[b].name));

        return {
            type: FOLDERS_SUCCESS,
            authorizedCollections,
            allIds,
            byId,
            byParentId,
        };
    }
    catch (e) {
        throw e;
    }
}

export const fetchFoldersEpic = (action$, state$) => action$.pipe(
    ofType(FOLDERS_REQUEST),
    switchMap(request =>
        concat(
            ensureLoggedIn$(action$, state$),
            fetchCollectionsIfNeeded$(action$, state$),
            defer(() => from(Google.getAllFolders())).pipe(
                withLatestFrom(state$),
                map(([allFolders, state]) => success(allFolders, state.collections)),
            ),
        ).pipe(catchError(error => of(failure(error))))
    )
);

export const fetchIfNeeded$ = (action$, state$) =>
    of(0).pipe(
        withLatestFrom(state$),
        map(([_, state]) => state.folders.isLoaded),
        filter(v => !v),
        switchMap(() => concat(
            of(0).pipe(
                withLatestFrom(state$),
                map(([_, state]) => shouldFetch(state)),
                filter(v => v),
                map(() => request())
            ),
            action$.pipe(
                ofType(FOLDERS_SUCCESS, FOLDERS_FAILURE),
                take(1), // listen for the response to come back...
                ofType(FOLDERS_FAILURE),
                map(() => { throw new Error(`Folders could not be loaded.`) }),
            )
        ))
    )

export const shouldFetch = (state) => {
    if (!state.folders)
        return true;
    else if (state.folders.isFetching)
        return false;
    else if (state.folders.error)
        return false;
    else if (!state.folders.isLoaded)
        return true;
    else
        return state.folders.didInvalidate;
}

export const fetchIfNeeded = () =>
    (dispatch, getState) => {
        if (!shouldFetch(getState()))
            return Promise.resolve();
        console.log(`fetching folders`)
        return dispatch(request());
    }
