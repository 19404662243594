import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { fetchIfNeeded } from '../actions/folders';

const useFolders = () => {
    const dispatch = useDispatch();
    const folders = useSelector(s => s.folders);
    useEffect(() => { dispatch(fetchIfNeeded()) });

    return (folders.isFetching || !folders.isLoaded)
        ? {
            areLoading: true,
            allIds: null,
            byId: null,
            byParentId: null,
        }
        : {
            areLoading: false,
            allIds: folders.allIds,
            byId: folders.byId,
            byParentId: folders.byParentId,
        };
}

export default useFolders;