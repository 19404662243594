import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import useAuthorizedCollections from '../hooks/useAuthorizedCollections';
import Spinner from '../components/Spinner';
import useNavigation, { ROOT } from '../hooks/useNavigation';
import useAuthorization from '../hooks/useAuthorization';
import '../style/CollectionList.scss';

const CollectionList = () => {
    const auth = useAuthorization();
    const nav = useNavigation();
    const authorizedCollections = useAuthorizedCollections();

    if (nav.route !== ROOT || !auth.isLoggedIn) return null;

    if (authorizedCollections.error)
        return (
            <div className='centering-container'>
                <p className="error center-text">
                    Error loading collections.<br />
                    You may not have the right membership or permissions.<br />
                    Please see your administrator.
                </p>
            </div>
        );

    if (authorizedCollections.areLoading)
        return (
            <div className='centering-container'>
                <Spinner color='gray' />
            </div>
        );

    return (
        <div className='centering-container'>
            <div>
                <Helmet><title>MIT Wiki</title></Helmet>
                <h3 className='title is-3'>Select a Collection</h3>
                <ul>
                    {authorizedCollections.all.map(c =>
                        <li className='collection-item' key={c.key}>
                            <Link to={`/${c.key}`}>{c.name}</Link>
                        </li>)}
                </ul>
            </div>
        </div>
    );
}

export default CollectionList;