import { useEffect, useState } from 'react';

import Google from '../util/Google';
import useAuthorization from './useAuthorization';

const useRevisionHistory = (id) => {
    const auth = useAuthorization();
    const [status, setStatus] = useState('empty');
    const [results, setResults] = useState(null);

    const readyToFetch = id && auth.isLoggedIn;

    useEffect(() => {
        if (!readyToFetch) return;

        const fetchData = async () => {
            setStatus('fetching');
            const response = await Google.getRevisions({ id })
            setResults(response);
            setStatus('fetched');
        };

        fetchData();
    }, [readyToFetch, id]);

    if (!id) return { noResults: 'noId' };
    if (!auth.isLoggedIn) return { noResults: 'isLoggedOut' };

    if (!readyToFetch || status !== 'fetched') return {
        areLoading: true,
        id,
    };
    
    return {
        areLoading: false,
        id,
        all: results,
    }
}

export default useRevisionHistory;