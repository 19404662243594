import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

import logo from '../style/Maddox-logo-orange.svg';
import Auth from '../containers/Auth';
import RequireAuthorization from '../containers/RequireAuthorization';
import CollectionMenu from '../components/CollectionMenu';
import SearchBar from '../containers/SearchBar';
import { Link } from 'react-router-dom';

export default function NavBar(props) {
    const navbar = useRef();
    const [isOpen, setIsOpen] = useState(false);

    const handleClickOutside = e => {
        if (navbar.current.contains(e.target)) {
            // inside click
            return;
        }
        // outside click
        setIsOpen(false);
    };

    const onBurgerClick = e => {
        e.preventDefault();
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    let menuClass = classNames({
        'navbar-menu': true,
        'is-active': isOpen
    });

    return (
        <nav ref={navbar} className="navbar primary-navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <Link to='/'>
                    <div className="navbar-item">
                        <img src={logo} alt='Maddox Wiki' width="28" height="28" />
                        <span className='brand-title'>Wiki</span>
                    </div>
                </Link>

                <div role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" onClick={onBurgerClick}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </div>
            </div>

            <div className={menuClass}>
                <div className="navbar-start">
                    <RequireAuthorization>
                        <CollectionMenu onClick={() => setIsOpen(false)} />
                        <div className="navbar-item search-bar">
                            <SearchBar onSelect={() => setIsOpen(false)} />
                        </div>
                    </RequireAuthorization>
                </div>
                <div className="navbar-end">
                    <Auth />
                </div>
            </div>
        </nav>
    );
}
