import React from 'react';
import classNames from 'classnames';

export default function DocumentNavBarFile({fileName, link, isSelected, onClick, showEmpty}) {
    var aClass = classNames({
        'menu-item': true,
        'is-active': isSelected
    });

    if (showEmpty)
        return <li><a className={aClass} href='#empty' onClick={e => {e.preventDefault(); }}>(Empty)</a></li>;

    return (
        <li>
            <a className={aClass} href={link}
                onClick={e => {e.preventDefault(); onClick(); }}>{fileName}</a>
        </li>
    );
}
