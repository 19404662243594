import React, { forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { toggle as toggleTreeState } from '../actions/treeState';
import DocumentTreeFile from './DocumentTreeFile';

import DocumentNavBarFolder from '../components/DocumentNavBarFolder';
import DocumentMenuFolder from '../components/DocumentMenuFolder';

export const SIGNED_OUT = 'SIGNED_OUT';
export const ERROR = 'ERROR';
export const NO_COLLECTION = 'NO_COLLECTION';
export const LOADING = 'LOADING';

const DocumentTreeFolder = forwardRef(({
    show, format, error,
    folderId, onSelect,
    selectedCollectionKey, selectedDocumentId, currentFileName, selectedCollectionLink, selectedCollectionName,
    isRoot, folders, documents
}, ref) => {
    const dispatch = useDispatch();
    const treeState = useSelector(s => s.treeState);
    const toggle = (id) => dispatch(toggleTreeState(id));

    if (show) return <SwitchFolder {...{ format, show, error }} />

    const handleClick = () => {
        toggle(folderId);
    };


    const isExpanded = treeState[folderId] ? true : false;
    const isFolder = id => id in folders.byId;
    // const byFolderId = collectionDocs.byFolderId[folderId] ?? [];
    const allChildIds = [
        ...folders.byParentId[folderId] || [],
        ...documents.byParentId[folderId] || []];
    const children = allChildIds.length > 0
        ? allChildIds.map(id =>
            isFolder(id)
                ? <DocumentTreeFolder {...{ format, key: id, folderId: id, onSelect, selectedCollectionKey, selectedDocumentId, folders, documents }} />
                : <DocumentTreeFile {...{ format, key: id, fileId: id, onSelect, selectedCollectionKey, selectedDocumentId, documents }} />)
        : [<DocumentTreeFile showEmpty {...{ format, key: folderId }} />];

    const folderName = isRoot ? null : folders.byId[folderId].name;
    const showAddChildren = true; // isRoot ? false : folders.byId[folderId].canAddChildren;
    if (isRoot) {
        return (
            <SwitchFolder {...{ ref, format, isRoot, folderId, folderName, currentFileName, selectedCollectionLink, selectedCollectionName, onClick: handleClick }}>
                {children}
            </SwitchFolder>);
    }
    else {
        return (
            <SwitchFolder {...{
                format, isRoot, isExpanded, folderId, showAddChildren, folderName,
                onClick: handleClick,
                link: `/${selectedCollectionKey}`
            }}>
                {children}
            </SwitchFolder>);
    }
})

const SwitchFolder = forwardRef((props, ref) => {
    switch (props.format) {
        case 'navbar':
            return <DocumentNavBarFolder ref={ref} {...props} />;
        case 'menu':
        default:
            return <DocumentMenuFolder ref={ref} {...props} />;
    }
})

export default DocumentTreeFolder;