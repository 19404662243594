import React from 'react';

import useAuthorization from '../hooks/useAuthorization';
import { Roller } from 'react-css-spinners'

const InitSpinner = () => {
    const auth = useAuthorization();

    if (auth.isInitializing) {
        return (
            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-content centering-container">
                    <Roller />
                </div>
            </div>
        );
    }

    return null;
}

export default InitSpinner;