import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { fetchIfNeeded } from '../actions/documents';

const useDocuments = () => {
    const dispatch = useDispatch();
    const documents = useSelector(s => s.documents);
    useEffect(() => { dispatch(fetchIfNeeded()) });

    return (!documents.isLoaded)
        ? {
            areLoaded: false,
            byId: null,
            byUrl: null,
            byParentId: null
        }
        : {
            areLoaded: true,
            byId: documents.byId,
            byUrl: documents.byUrl,
            byParentId: documents.byParentId
        };
}

export default useDocuments;