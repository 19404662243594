import React from 'react';

import useRevisionHistory from '../hooks/useRevisionHistory';
import Spinner from './Spinner';
import useMeta from '../hooks/useMeta';
import { formatDateTime } from '../util/date';

const RevisionHistory = () => {
    const meta = useMeta();
    const revisions = useRevisionHistory(meta.id);

    if (revisions.noResults) return null;

    if (revisions.areLoading || meta.isLoading)
        return (
            <div className='centering-container'>
                <Spinner color='gray' />
            </div>
        );

    const getExportLink = (r, type, text) => {
        const link = r.exportLinks[type];
        if (!link) return null;
        return (
            <a href={link} target='_blank' rel='noopener noreferrer'>
                {text}
            </a>);
    }

    return (
        <div>
            <h5 className='title is-5'>{meta.name}</h5>
            <br />
            <table className="table is-striped">
                <thead>
                    <tr>
                        <th>Modified On</th>
                        <th>Modified By</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {revisions.all.sort((a, b) => (a.modifiedTime > b.modifiedTime) ? -1 : 1).map(r => (
                        <tr key={r.id}>
                            <td>{formatDateTime(r.modifiedTime)}</td>
                            <td>{r.lastModifyingUser.displayName}</td>
                            <td>
                                {getExportLink(r, 'text/html', '[HTML]')}
                                &nbsp;|&nbsp;
                                {getExportLink(r, 'application/pdf', '[PDF]')}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default RevisionHistory;