import { switchMap, map, catchError } from 'rxjs/operators';
import { of, from, concat } from 'rxjs';
import { ofType } from 'redux-observable';

import Google from '../util/Google';
import { getMimeType$, fetchIfNeeded$ as fetchMetaIfNeeded$ } from './meta';

export const DOC_CONTENT_FETCH_REQUEST = 'DOC_CONTENT_FETCH_REQUEST'
export const DOC_CONTENT_FETCH_SUCCESS = 'DOC_CONTENT_FETCH_SUCCESS'
export const DOC_CONTENT_FETCH_FAILURE = 'DOC_CONTENT_FETCH_FAILURE'
export const DOC_CONTENT_INVALIDATE = 'DOC_CONTENT_INVALIDATE'

export const request = (id) => ({
    type: DOC_CONTENT_FETCH_REQUEST,
    id
})
const failure = (id, error) => ({
    type: DOC_CONTENT_FETCH_FAILURE,
    id,
    error
})
export const invalidate = (id) => ({
    type: DOC_CONTENT_INVALIDATE,
    id
})

const getFile$ = (id, mimeType) => from(Google.getFileContent({ id, mimeType }));

export const fetchDocContentsEpic = (action$, state$) => action$.pipe(
    ofType(DOC_CONTENT_FETCH_REQUEST),
    switchMap(request =>
        concat(
            fetchMetaIfNeeded$(action$, state$, request.id),
            getMimeType$(state$, request.id).pipe(
                switchMap(mimeType => getFile$(request.id, mimeType).pipe(
                    map(content => ({
                        type: DOC_CONTENT_FETCH_SUCCESS,
                        id: request.id,
                        body: content
                    }))
                )))
        ).pipe(catchError(error => of(failure(request.key, error))))
    )
);

export const shouldFetch = (state, id) => {
    const document = state.docContents[id];
    if (!document)
        return true;
    else if (document.isFetching)
        return false;
    else if (!document.isLoaded)
        return true;    
    else
        return document.didInvalidate;
}

export const fetchIfNeeded = (id) =>
    (dispatch, getState) => {
        if (!shouldFetch(getState(), id))
            return Promise.resolve();
        console.log(`fetching content for '${id}'`)
        return dispatch(request(id,));
    }
