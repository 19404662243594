import React from 'react';

import DocumentNavBarFile from '../components/DocumentNavBarFile';
import DocumentMenuFile from '../components/DocumentMenuFile';

export default function DocumentTreeFile({ format, documents, fileId, onSelect,
    selectedCollectionKey, selectedDocumentId, showEmpty }) {
    if (showEmpty)
        return <SwitchFile format={format} showEmpty />;

    const file = documents.byId[fileId];
    const isSelected = (file.id === selectedDocumentId);

    const handleClick = () => {
        if (onSelect) onSelect({ id: file.id, url: file.url });
    };

    return <SwitchFile format={format} link={`/${file.url ? file.url : file.id}`} isSelected={isSelected}
        fileName={file.name} onClick={handleClick} />;
}

const SwitchFile = (props) => {
    switch (props.format) {
        case 'navbar':
            return <DocumentNavBarFile {...props} />;
        case 'menu':
        default:
            return <DocumentMenuFile {...props} />;
    }
}
