import { useSelector } from 'react-redux'

const useAuthorization = () => {
    const auth = useSelector(s => s.auth);

    return {
        ...auth,
        isInitializing: !auth.isInitialized,
        isLoggedOut: auth.isInitialized && !auth.isLoggedIn,
    };
}

export default useAuthorization;