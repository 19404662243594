import { combineReducers } from 'redux'
import auth from './auth'
import collections from './collections'
import treeState from './treeState'
import docContents from './docContents'
import folders from './folders'
import documents from './documents'
import meta from './meta'
import { AUTH_LOGOUT_SUCCESS } from '../actions/auth'

const appReducer = combineReducers({
    auth,
    collections,
    treeState,
    docContents,
    folders,
    documents,
    meta,
})

const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === AUTH_LOGOUT_SUCCESS) {
        state = { auth: state.auth };
    }
    return appReducer(state, action);
};

export default rootReducer;

/*
{
    documents: {
        byCollection: {key => {
            isFetching: bool,
            allIds: [string],
            rootIds: [string]
        }},
        byId: {id => {
            isFetching: bool,

            id: string,
            title: string,
            mimeType: string
        }}
    },
    documentTree: {
        id => boolean
    },
    document: {
        isFetching: bool,
        id: string,
        title: string,
        mimeType: string
    },
    bodies: {
        byId: {id => {
            isFetching: bool,
            lastUpdated: timestamp,
            body: string
        }},
        allIds: [string]
    },
    search: {
        isFetching: bool,
        lastQuery: {
            query: string,
            scope: [string]
        },
        lastResult: [{
            collection: string,
            id: string,
            title: string,
            snippet: string
        }]
    }
}

*/