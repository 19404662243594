import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { fetchIfNeeded } from '../actions/meta';
import useNavigation from '../hooks/useNavigation';

const useMeta = () => {
    const dispatch = useDispatch();

    const meta = useSelector(s => s.meta);
    const documents = useSelector(s => s.documents);
    const documentsAreLoading = !documents || documents.isFetching || !documents.isLoaded;

    const nav = useNavigation();

    useEffect(() => {
        if (documentsAreLoading && nav.documentLoc) dispatch(fetchIfNeeded(nav.documentLoc));
    }, [documentsAreLoading, nav.documentLoc, dispatch]);

    if (!nav.documentLoc)
        return {
            isLoading: false,
            id: null,
            name: 'No Document Selected',
            description: null,
            mimeType: null,
            webViewLink: null,
            url: null,
            modifiedTime: null,
            canEdit: null,
            canReadRevisions: null,
        };

    const metaIsLoading = !meta || !(nav.documentLoc in meta) || meta[nav.documentLoc].isFetching || !meta[nav.documentLoc].isLoaded;
    if (documentsAreLoading && metaIsLoading)
        return {
            isLoading: true,
            loc: nav.documentLoc,
            id: null,
            description: null,
            url: null,
            name: 'Loading...',
            mimeType: null,
            webViewLink: null,
            modifiedTime: null,
            canEdit: null,
            canReadRevisions: null,
        };

    const document = (() => {
        if (documentsAreLoading)
            return meta[nav.documentLoc];
        if (nav.documentLoc in documents.byId)
            return documents.byId[nav.documentLoc]
        if (nav.documentLoc in documents.byUrl) {
            const ids = documents.byUrl[nav.documentLoc];
            if (ids.length === 1)
                return documents.byId[ids[0]];
        }
        return undefined;
    })();

    if (!document)
        return {
            isLoading: false,
            id: null,
            name: 'Document Deleted or Unavailable',
            description: null,
            mimeType: null,
            webViewLink: null,
            url: null,
            modifiedTime: null,
            canEdit: null,
            canReadRevisions: null,
        };

    return {
        isLoading: false,
        id: document.id,
        name: document.name,
        description: document.description,
        mimeType: document.mimeType,
        webViewLink: document.webViewLink,
        url: document.url,
        modifiedTime: document.modifiedTime,
        canEdit: document.canEdit,
        canReadRevisions: document.canReadRevisions,
    };
}

export default useMeta;