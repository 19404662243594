import { switchMap, withLatestFrom, map, catchError } from 'rxjs/operators';
import { of, from, concat } from 'rxjs';
import { ofType } from 'redux-observable';

import Google from '../util/Google';

import { fetchIfNeeded$ as fetchFoldersIfNeeded$ } from './folders';
import { ensureLoggedIn$ } from './auth';
import { dateDiffInSeconds } from '../util/date';

export const DOCUMENTS_REQUEST = 'DOCUMENTS_REQUEST'
export const DOCUMENTS_SUCCESS = 'DOCUMENTS_SUCCESS'
export const DOCUMENTS_FAILURE = 'DOCUMENTS_FAILURE'
export const DOCUMENTS_INVALIDATE = 'DOCUMENTS_INVALIDATE'
export const DOCUMENT_ADD = 'DOCUMENT_ADD'
export const DOCUMENT_UPDATE = 'DOCUMENT_UPDATE'

export const add = (document) => ({
    type: DOCUMENT_ADD,
    document, /*: {
        id: f.id,
        name: f.name,
        description: f.description,
        mimeType: f.mimeType,
        webViewLink: f.webViewLink,
        modifiedTime: f.modifiedTime,
        parentId,
        collection: folder.collection,
        canEdit: f.capabilities.canEdit,
        canReadRevision: f.capabilities.canReadRevisions,
    }*/
})

export const update = (document) => ({
    type: DOCUMENT_UPDATE,
    document, /*: {
        id: f.id,
        name: f.name,
        description: f.description,
        mimeType: f.mimeType,
        webViewLink: f.webViewLink,
        modifiedTime: f.modifiedTime,
        parentId,
        collection: folder.collection,
        canEdit: f.capabilities.canEdit,
        canReadRevision: f.capabilities.canReadRevisions,
    }*/
})

export const request = () => ({
    type: DOCUMENTS_REQUEST
})
const failure = (error) => {
    if (error instanceof Error)
        error = {
            message: error.message,
            stack: error.stack
        };
    return {
        type: DOCUMENTS_FAILURE,
        error,
    };
}
export const invalidate = () => ({
    type: DOCUMENTS_INVALIDATE
})
const success = (files, folders) => {
    const byId = files.reduce((a, f) => {
        // orphaned files cannot be part of the wiki and will be filtered out here
        if (!f.parents) return a;

        const parentId = f.parents.find(pid => pid in folders.byId);
        const folder = folders.byId[parentId];

        // files not in a known folder cannot be part of the wiki and will be filtered out here
        if (!folder) return a;

        a[f.id] = {
            id: f.id,
            url: f.url,
            name: f.name,
            description: f.description,
            mimeType: f.mimeType,
            webViewLink: f.webViewLink,
            modifiedTime: f.modifiedTime,
            parentId,
            collection: folder.collection,
            canEdit: f.capabilities.canEdit,
            canReadRevisions: f.capabilities.canReadRevisions,
        };
        return a;
    }, {});

    return {
        type: DOCUMENTS_SUCCESS,
        byId,
        lastRefreshed: new Date(),
    };
}

export const fetchDocumentsEpic = (action$, state$) => action$.pipe(
    ofType(DOCUMENTS_REQUEST),
    switchMap(request =>
        concat(
            ensureLoggedIn$(action$, state$),
            fetchFoldersIfNeeded$(action$, state$),
            of(request).pipe(
                withLatestFrom(state$),
                switchMap(([_, state]) =>
                    from(Google.getFiles({ parentIds: state.folders.allIds })).pipe(
                        map(files => success(files, state.folders)))
                )
            )
        ).pipe(catchError(error => of(failure(error))))
    )
);

// export const fetchIfNeeded$ = (action$, state$, id) =>
//     of(shouldFetch(state$.value, id)).pipe(
//         //tap(v => console.log(`shouldFetch(state$.value, '${id}') = ${v}`)),
//         filter(v => v),
//         switchMap(() =>
//             action$.pipe(
//                 ofType(DOC_META_SUCCESS, DOC_META_FAILURE),
//                 take(1), // listen for the response to come back...
//                 ofType(DOC_META_FAILURE),
//                 map(() => { throw new Error(`Meta could not be loaded for id '${id}'.`) }),
//                 startWith(request(id))
//             )
//         )
//     )

// export const getMimeType$ = (state$, id) =>
//     of(id).pipe(
//         withLatestFrom(state$),
//         map(([id, state]) => state.docMetas[id].mimeType)
//     )

export const shouldFetch = (state) => {
    if (!state.documents)
        return true;
    else if (state.documents.isFetching)
        return false;
    else if (state.documents.error)
        return false;
    else if (!state.documents.isLoaded)
        return true;
    else
        return state.documents.didInvalidate;
}

export const fetchIfNeeded = () =>
    (dispatch, getState) => {
        if (!shouldFetch(getState()))
            return Promise.resolve();
        console.log(`fetching documents`)
        return dispatch(request());
    }

export const shouldRefetch = (state, seconds) => {
    if (!state.documents)
        return true;
    else if (state.documents.isFetching)
        return false;
    else if (state.documents.error)
        return false;
    else if (!state.documents.isLoaded)
        return true;
    else if (state.documents.didInvalidate)
        return true;
    else if (!state.documents.lastRefreshed)
        return true;
    else
        return dateDiffInSeconds(state.documents.lastRefreshed) > seconds;
}

export const refetchIfNeeded = (seconds) =>
    (dispatch, getState) => {
        if (!shouldRefetch(getState(), seconds))
            return Promise.resolve();
        console.log(`re-fetching documents`)
        return dispatch(request());
    }
