import { filter, switchMap, withLatestFrom, map, take, startWith, catchError } from 'rxjs/operators';
import { of, from, defer, concat } from 'rxjs';
import { ofType } from 'redux-observable';

import Google from '../util/Google';
import { ensureLoggedIn$ } from './auth';

export const META_REQUEST = 'META_REQUEST'
export const META_SUCCESS = 'META_SUCCESS'
export const META_FAILURE = 'META_FAILURE'
export const META_INVALIDATE = 'META_INVALIDATE'

export const request = (loc) => ({
    type: META_REQUEST,
    loc
})
const failure = (loc, error) => ({
    type: META_FAILURE,
    loc,
    error
})
export const invalidate = (loc) => ({
    type: META_INVALIDATE,
    loc
})

const getFileMeta$ = (loc) => from(Google.getFile({ loc }));

export const fetchMetaEpic = (action$, state$) => action$.pipe(
    ofType(META_REQUEST),
    switchMap(request =>
        concat(
            ensureLoggedIn$(action$, state$),
            defer(() => getFileMeta$(request.loc)).pipe(
                map(file => ({
                    type: META_SUCCESS,
                    loc: request.loc,
                    id: file.id,
                    url: file.url,
                    name: file.name,
                    description: file.description,
                    mimeType: file.mimeType,
                    webViewLink: file.webViewLink,
                    canEdit: file.capabilities.canEdit,
                    canReadRevisions: file.capabilities.canReadRevisions,
                    modifiedTime: file.modifiedTime,
                }))
            )
        ).pipe(catchError(error => of(failure(request.key, error))))
    )
);

export const fetchIfNeeded$ = (action$, state$, loc) =>
    of(0).pipe(
        withLatestFrom(state$),
        map(([_, state]) => {
            const doc = lookupByLoc(loc, state.documents);
            if (doc) return false;
            return shouldFetch(state, loc);
        }),
        //tap(v => console.log(`shouldFetch(state$.value, '${id}') = ${v}`)),
        filter(v => v),
        switchMap(() =>
            action$.pipe(
                ofType(META_SUCCESS, META_FAILURE),
                take(1), // listen for the response to come back...
                ofType(META_FAILURE),
                map(() => { throw new Error(`Meta could not be loaded for loc '${loc}'.`) }),
                startWith(request(loc))
            )
        )
    )

const lookupByLoc = (loc, documents) => {
    if (!documents)
        return undefined;
    if (!documents.isLoaded)
        return undefined;
    if (loc in documents.byId)
        return documents.byId[loc];
    if (loc in documents.byUrl) {
        const id = documents.byUrl[loc];
        if (id in documents.byId)
            return documents.byId[id];
    }
    return undefined;
}

export const getMimeType$ = (state$, loc) =>
    of(loc).pipe(
        withLatestFrom(state$),
        map(([loc, state]) => {
            const doc = lookupByLoc(loc, state.documents);
            return doc
                ? doc.mimeType
                : state.meta[loc].mimeType;
        })
    )

export const shouldFetch = (state, loc) => {
    const meta = state.meta[loc];
    if (!meta)
        return true;
    else if (meta.isFetching)
        return false;
    else if (!meta.isLoaded)
        return false;
    else
        return meta.didInvalidate;
}

export const fetchIfNeeded = (loc) =>
    (dispatch, getState) => {
        if (!shouldFetch(getState(), loc))
            return Promise.resolve();
        console.log(`fetching meta for '${loc}'`)
        return dispatch(request(loc));
    }
