import React from 'react';
import { Helmet } from 'react-helmet-async';

import Spinner from '../components/Spinner';
import { Link } from 'react-router-dom';
import useSearchResults from '../hooks/useSearchResults';

const SearchResults = () => {
    const results = useSearchResults();

    if (results.noSearch) return null;

    if (results.areLoading)
        return (
            <div className='centering-container'>
                <Spinner color='gray' />
            </div>
        );

    return (
        <div className='content'>
            <Helmet><title>Search - {results.query}</title></Helmet>
            <h3 className='title is-3'>Results for '{results.query}'</h3>
            <hr />
            <table className="table is-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Collection</th>
                    </tr>
                </thead>
                <tbody>
                    {results.all.map(r => (
                        <tr key={r.id}>
                            <td><Link to={`/${r.id}`}>{r.name}</Link></td>
                            <td><Link to={`/${r.collection.key}`}>{r.collection.name}</Link></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>);
}

export default SearchResults;