import React, { useState, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import Autosuggest from 'react-autosuggest';

import theme from '../style/SearchBar.module.scss';
import useDocuments from '../hooks/useDocuments';

const SearchBar = ({ onSelect }) => {
    const documents = useDocuments();

    const history = useHistory();
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [highlighted, setHighlighted] = useState(null);

    // *************************

    // Teach Autosuggest how to calculate suggestions for any given input value.
    const getSuggestions = useCallback(value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        if (inputLength === 0) return [];
        let results = Object.entries(documents.byId).filter(([_, doc]) =>
            doc.mimeType !== 'application/vnd.google-apps.folder' &&
            (
                doc.name.toLowerCase().includes(inputValue) ||
                (doc.url && doc.url.toLowerCase().slice(0, inputLength.length) === inputValue)
            )
        ).map(([_, doc]) => doc);
        return results;

        // return inputLength === 0 ? [] : languages.filter(lang =>
        //     lang.name.toLowerCase().slice(0, inputLength) === inputValue
        // );
    }, [documents.byId]);

    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    const getSuggestionValue = suggestion => suggestion.name;

    // Use your imagination to render suggestions.
    const renderSuggestion = suggestion => (
        <div className='columns is-gapless is-vcentered'>
            <div className='column'>{suggestion.name}</div>
            <div className='column is-narrow is-hidden-mobile'>
                <a className="external-link" href={`/${suggestion.id}`} onClick={e => e.stopPropagation()}>
                    <i aria-hidden />
                    <span className="visible-hidden">{suggestion.name}</span>
                </a>
            </div>
        </div>
    );

    const onSelection = (event, { suggestion }) => {
        history.push(`/${suggestion.id}`);
        setValue('');
        if (onSelect) onSelect(suggestion.id);
    }

    // *************************

    const onChange = (event, { newValue }) => {
        setValue(newValue);
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const onSuggestionHighlighted = ({ suggestion }) => {
        setHighlighted(suggestion);
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 13 /* ENTER */) {
            if (!highlighted) {
                e.preventDefault();
                history.push(`/search/${encodeURIComponent(value)}`);
                setValue('');
            }
        }
    };

    if (!documents.areLoaded) {
        return <div className='no-hover'>
            <Autosuggest
                theme={theme}
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                onSuggestionSelected={onSelection}
                renderSuggestion={renderSuggestion}
                inputProps={{
                    placeholder: 'Loading...',
                    value,
                    onChange
                }} />
        </div>;
    }

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
        placeholder: 'Search...',
        value,
        onChange,
        onKeyDown,
    };

    // Finally, render it!
    return (
        <Autosuggest
            theme={theme}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            onSuggestionSelected={onSelection}
            onSuggestionHighlighted={onSuggestionHighlighted}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
        />
    );
}

export default SearchBar;