import { useEffect, useState } from 'react';

import Google from '../util/Google';
import useNavigation, { SEARCH } from '../hooks/useNavigation';
import useAuthorization from '../hooks/useAuthorization';
import useAuthorizedCollections from '../hooks/useAuthorizedCollections';
import useDocuments from '../hooks/useDocuments';
import useFolders from '../hooks/useFolders';

const useSearchResults = () => {
    const auth = useAuthorization();
    const nav = useNavigation(); 
    const authorizedCollections = useAuthorizedCollections();
    const folders = useFolders();
    const documents = useDocuments();
    const [status, setStatus] = useState('empty');
    const [results, setResults] = useState(null);

    const readyToSearch = nav.route === SEARCH
        && auth.isLoggedIn
        && !authorizedCollections.areLoading
        && !folders.areLoading
        && documents.areLoaded;

    useEffect(() => {
        if (!readyToSearch) return;

        const fetchData = async () => {
            setStatus('fetching');
            const response = await Google.getFiles({ parentIds: folders.allIds, query: nav.query })
            setResults(response);
            setStatus('fetched');
        };

        fetchData();
    }, [readyToSearch, folders.allIds, nav.query]);

    if (nav.route !== SEARCH) return { noSearch: 'route' };
    if (!auth.isLoggedIn) return { noSearch: 'isLoggedOut' };

    if (!readyToSearch || status !== 'fetched') return {
        areLoading: true,
        query: nav.query,
    };
    
    const getCollection = (id) => {
        const key = documents.byId[id].collection;
        return authorizedCollections.byKey[key];
    }

    return {
        areLoading: false,
        query: nav.query,
        all: results.map(r => ({...r, collection: getCollection(r.id) })),
    }
}

export default useSearchResults;