import Google from '../util/Google';
import config from '../config';

import { map, filter, take } from 'rxjs/operators';

export const AUTH_INIT_BEGIN = 'AUTH_INIT_BEGIN'
export const AUTH_INIT_SUCCESS = 'AUTH_INIT_SUCCESS'
export const AUTH_INIT_FAILURE = 'AUTH_INIT_FAILURE'
export const AUTH_LOGIN_BEGIN = 'AUTH_LOGIN_BEGIN'
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE'
export const AUTH_LOGOUT_BEGIN = 'AUTH_LOGOUT_BEGIN'
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS'
export const AUTH_LOGOUT_FAILURE = 'AUTH_LOGOUT_FAILURE'

export const AUTH_STATUS_UNINITIALIZED = 'uninitialized'
export const AUTH_STATUS_INITIALIZING = 'initializing'
export const AUTH_STATUS_INITIALIZED = 'initialized'

/* Initialization Action Generators */
const initSuccess = (isLoggedIn, loggedInAs) => ({
    type: AUTH_INIT_SUCCESS,
    isLoggedIn,
    loggedInAs
})
const initFailure = (error) => ({
    type: AUTH_INIT_FAILURE,
    error
})
const getIdentity = user => user.getBasicProfile().getName();
const getGrantedScopes = user => user.getGrantedScopes();
export const init = () => {
    return async (dispatch, getState) => {
        // because our action attaches a listener to the authInstance singleton,
        // we want to make sure we don't initialize more than once
        const authStatus = getState().auth.status;
        if (authStatus === AUTH_STATUS_INITIALIZING || authStatus === AUTH_STATUS_INITIALIZED)
            return Promise.resolve();

        dispatch({ type: AUTH_INIT_BEGIN });

        try {
            const authInstance = await Google.getAuthInstance()
            // listen for future changes to login state
            authInstance.isSignedIn.listen(isSignedIn => {
                if (isSignedIn) {
                    const user = authInstance.currentUser.get();
                    if (!getGrantedScopes(user).split(' ').includes(config.gapi.scope)) {
                        authInstance.signOut();
                        return dispatch(loginSuccess(false, null));
                    }
                    dispatch(loginSuccess(getIdentity(authInstance.currentUser.get())))
                }
                else
                    dispatch(logoutSuccess())
            })

            if (authInstance.isSignedIn.get()) {
                const user = authInstance.currentUser.get();
                if (!getGrantedScopes(user).split(' ').includes(config.gapi.scope)) {
                    authInstance.signOut();
                    return dispatch(initSuccess(false, null));
                }
                return dispatch(initSuccess(true, getIdentity(authInstance.currentUser.get())))
            }
            else
                return dispatch(initSuccess(false, null))
        }
        catch (error) {
            return dispatch(initFailure(error))
        }
    }
}

/* Login Action Generators */
const loginSuccess = (loggedInAs) => ({
    type: AUTH_LOGIN_SUCCESS,
    loggedInAs
})
const loginFailure = (error) => ({
    type: AUTH_LOGIN_FAILURE,
    error
})
export const login = () => {
    return async (dispatch) => {
        dispatch({ type: AUTH_LOGIN_BEGIN });

        try {
            const authInstance = await Google.getAuthInstance()
            return authInstance.signIn()
        }
        catch (error) {
            return dispatch(loginFailure(error))
        }
    }
}

/* Logout Action Generators */
const logoutSuccess = (loggedInAs) => ({
    type: AUTH_LOGOUT_SUCCESS,
    loggedInAs
})
const logoutFailure = (error) => ({
    type: AUTH_LOGOUT_FAILURE,
    error
})
export const logout = () => {
    return async (dispatch) => {
        dispatch({ type: AUTH_LOGOUT_BEGIN });

        try {
            const authInstance = await Google.getAuthInstance()
            return authInstance.signOut()
        }
        catch (error) {
            return dispatch(logoutFailure(error))
        }
    }
}

export const ensureLoggedIn$ = (action$, state$) =>
    state$.pipe(
        map(state => state.auth.isLoggedIn),
        filter(v => v),
        take(1),
        filter(v => false)
    )
