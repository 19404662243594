import { useHistory } from "react-router-dom";

import useNavigation, { PENDING, DOCUMENT } from './useNavigation';

const useSelectedDocument = () => {

    const nav = useNavigation();

    const history = useHistory();
    const selectDocument = (loc) => {
        history.push(`/${loc}`);
    }

    const selectedDocument = (() => {
        if (nav.route === PENDING)
            return {
                isLoading: true,
                id: null,
            };

        if (!nav.documentId || nav.route !== DOCUMENT)
            return {
                isLoading: false,
                id: null,
            };

        return {
            isLoading: false,
            id: nav.documentId,
        };
    })();

    return { selectedDocument, selectDocument };
}

export default useSelectedDocument;