import {
    DOC_CONTENT_INVALIDATE, DOC_CONTENT_FETCH_REQUEST, DOC_CONTENT_FETCH_SUCCESS, DOC_CONTENT_FETCH_FAILURE,
} from '../actions/docContents';

const initialState = {
    isFetching: false,
    didInvalidate: false,
    isLoaded: false,
    id: null, // string
    body: null, // string
    error: null
}

const docContent = (state = initialState, action) => {
    switch (action.type) {
        case DOC_CONTENT_FETCH_REQUEST:
            return { ...state,                 
                isFetching: true,
                id: action.id };
        case DOC_CONTENT_FETCH_SUCCESS:
            return {
                ...state,
                isFetching: false,
                didInvalidate: false,
                isLoaded: true,
                body: action.body,
                id: action.id
            }
        case DOC_CONTENT_FETCH_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error,
                id: action.id
            }
        case DOC_CONTENT_INVALIDATE:
            return {
                ...state,
                didInvalidate: true,
                id: action.id
            }
        default:
            return state
    }
}

const docContents = (state = {}, action) => {
    switch (action.type) {
        case DOC_CONTENT_FETCH_REQUEST:
        case DOC_CONTENT_FETCH_SUCCESS:
        case DOC_CONTENT_FETCH_FAILURE:
        case DOC_CONTENT_INVALIDATE:
            return { ...state, [action.id]: docContent(state[action.id], action) };
        default:
            return state
    }
}

export default docContents