import { combineEpics } from 'redux-observable'
import { fetchDocContentsEpic } from '../actions/docContents'
import { fetchFoldersEpic } from '../actions/folders'
import { fetchDocumentsEpic } from '../actions/documents'
import { fetchMetaEpic } from '../actions/meta'

export default combineEpics(
    fetchMetaEpic,
    fetchDocContentsEpic,
    fetchFoldersEpic,
    fetchDocumentsEpic
)
