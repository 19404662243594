import { useRouteMatch } from "react-router-dom";
import { useSelector } from 'react-redux'

// import { fetchIfNeeded as fetchCollectionsIfNeeded } from '../actions/collections';
// import { fetchIfNeeded as fetchDocumentsIfNeeded } from '../actions/documents';

export const ROOT = 'root';
export const PENDING = 'pending';
export const COLLECTION = 'collection';
export const DOCUMENT = 'document';
export const SEARCH = 'search';

export const INVALID = 'invalid';

export const COLLECTIONS = 'collections';
export const FOLDERS = 'folders';
export const DOCUMENTS = 'documents';

/* loads (if needed):
 *     collections
 *     folders
 *     documents (for id in route)
 * provides to children (as props):
 *     route: string,         // possible values: ROOT, PENDING, COLLECTION, DOCUMENT, SEARCH
 *     waitingOn: string,     // possible values: null, COLLECTIONS, FOLDERS, DOCUMENTS
 *     collectionKey: string, // possible values: null, PENDING, INVALID, <key>
 *     documentId: string,    // possible values: null, <id>
 */
const useNavigation = (/*{ collections, folders, documents,
    fetchCollectionsIfNeeded, fetchFoldersIfNeeded, fetchDocMetaIfNeeded,
    children, ...props }*/) => {

    const collections = useSelector(s => s.collections);
    const folders = useSelector(s => s.folders);
    const documents = useSelector(s => s.documents);

    // useEffect(() => {
    //     fetchCollectionsIfNeeded();
    //     fetchDocumentsIfNeeded();
    // });

    //const path = collections.isLoaded ? `/:collection(${collections.allKeys.join('|')})/:id?` : '/';
    const path = `/:loc/:query?`;
    const match = useRouteMatch(path);
    const locFromRoute = match && match.params.loc ? match.params.loc : null;
    const queryFromRoute = match && match.params.query ? match.params.query : null;

    return getNavigation(locFromRoute, queryFromRoute, collections, folders, documents);
}

/* returns an object of type: {
    route: string,         // possible values: ROOT, PENDING, COLLECTION, DOCUMENT, SEARCH
    waitingOn: string,     // possible values: null, COLLECTIONS, FOLDERS, DOCUMENTS
    collectionKey: string, // possible values: null, PENDING, INVALID, <key>
    documentId: string,    // possible values: null, <id>
} */
const getNavigation = (locFromRoute, queryFromRoute, collections, folders, documents) => {
    if (!locFromRoute)
        return { route: ROOT };
    if (!collections || !collections.isLoaded)
        return { route: PENDING, waitingOn: COLLECTIONS };
    if (locFromRoute in collections.byKey)
        return { route: COLLECTION, collectionKey: locFromRoute };
    if (locFromRoute === SEARCH)
        return { route: SEARCH, query: decodeURIComponent(queryFromRoute) };
    if (!folders || !folders.isLoaded)
        return { route: DOCUMENT, collectionKey: PENDING, waitingOn: FOLDERS, documentLoc: locFromRoute };
    if (!documents || !documents.isLoaded)
        return { route: DOCUMENT, collectionKey: PENDING, waitingOn: DOCUMENTS, documentLoc: locFromRoute };
    const id = (() => {
        if (locFromRoute in documents.byId)
            return locFromRoute;
        if (locFromRoute in documents.byUrl) {
            const ids = documents.byUrl[locFromRoute];
            if (ids.length === 1)
                return ids[0];
            if (ids.length > 1)
                return ids;
        }
        return undefined;
    })();
    if (id && !Array.isArray(id) && id in documents.byId) {
        const doc = documents.byId[id];
        if (doc.parentId in folders.byId) {
            return {
                route: DOCUMENT,
                collectionKey: folders.byId[doc.parentId].collection,
                documentId: id,
                documentUrl: doc.url,
                documentLoc: locFromRoute,
            };
        }
    }
    if (id && Array.isArray(id))
        return {
            route: DOCUMENT,
            collectionKey: INVALID,
            documentIds: id,
            documentLoc: locFromRoute,
        };
    // if (!(locFromRoute in documents.byId) || !(documents.byId[locFromRoute].parentId in folders.byId))
    return { route: DOCUMENT, collectionKey: INVALID, documentLoc: locFromRoute };
}

// export default connect(
//     s => ({ folders: s.folders, collections: s.collections, documents: s.documents }),
//     { fetchCollectionsIfNeeded, fetchDocumentsIfNeeded }
// )(NavigationProvider)

export default useNavigation;