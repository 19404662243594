
const gRedirectPattern = /^https:\/\/www\.google\.com\/url\?q=(?<url>http(s)?:\/\/[^&]*)(&.*|$)/;
const gDocsPattern = /^https:\/\/www\.google\.com\/url\?q=https:\/\/docs\.google\.com\/document\/d\/(?<id>[^/]*)(\/.*|$)/;

// (url: string) => string (Google Drive File ID) | undefined
export const parseGoogleDriveLink = (url) => {
    const match = gDocsPattern.exec(url);
    return match && match.groups.id
        ? match.groups.id
        : undefined;
}

// (url: string) => string (url) | undefined
export const parseGoogleRedirectLink = (url) => {
    const match = gRedirectPattern.exec(url);
    return match && match.groups.url
        ? decodeURIComponent(match.groups.url)
        : undefined;
}

export const rewriteLinks = (url, documents) => {
    const id = parseGoogleDriveLink(url);
    if (id) {
        if (!documents || !documents.areLoaded || id in documents.byId) {
            const doc = documents && documents.areLoaded && id in documents.byId ? documents.byId[id] : undefined;
            if (doc && doc.url)
                return { rewrite: true, path: `/${doc.url}` };
            else
                return { rewrite: true, path: `/${id}` };
        }
        // we won't rewrite links for docs not part of the wiki
    }

    const redirect = parseGoogleRedirectLink(url);
    if (redirect)
        return { rewrite: true, href: redirect };

    // Future: Rewrite MIT Core SOP URLs?
    
    return { rewrite: false }; 
}