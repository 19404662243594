import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createEpicMiddleware } from 'redux-observable';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import rootReducer from "./reducers";
import rootEpic from './epics';

import { AddDocumentProvider } from './components/AddDocument';
import { EditDocumentProvider } from './components/EditDocument';
import App from './App';
import { init as authInit } from './actions/auth';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const epicMiddleware = createEpicMiddleware();
const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware, epicMiddleware));
const store = createStore(rootReducer, enhancer);
epicMiddleware.run(rootEpic);

store.dispatch(authInit());
//store.dispatch(fetchCollectionsIfNeeded());

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <HelmetProvider>
                    <AddDocumentProvider>
                        <EditDocumentProvider>
                            <App />
                        </EditDocumentProvider>
                    </AddDocumentProvider>
                </HelmetProvider>
            </Router>
        </Provider>
    </React.StrictMode>, document.getElementById('root'));
