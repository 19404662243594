import {
    META_INVALIDATE, META_REQUEST, META_SUCCESS, META_FAILURE,
} from '../actions/meta';

const initialState = {
    id: null, // string
    url: null, // string
    isFetching: false,
    didInvalidate: false,
    isLoaded: false,
    name: null, // string
    description: null, // string
    mimeType: null, // string
    webViewLink: null, // string
    canEdit: false,
    canReadRevisions: false,
    modifiedTime: null, // datetime
    error: null // string
}

const meta = (state = initialState, action) => {
    switch (action.type) {
        case META_REQUEST:
            return { ...state, isFetching: true, id: action.id };
        case META_SUCCESS:
            return {
                ...state,
                isFetching: false,
                didInvalidate: false,
                isLoaded: true,
                name: action.name,
                description: action.description,
                mimeType: action.mimeType,
                webViewLink: action.webViewLink,
                modifiedTime: action.modifiedTime,
                canEdit: action.canEdit,
                canReadRevisions: action.canReadRevisions,
                url: action.url,
                id: action.id
            }
        case META_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error,
                url: action.url,
                id: action.id
            }
        case META_INVALIDATE:
            return {
                ...state,
                didInvalidate: true,
                url: action.url,
                id: action.id
            }
        default:
            return state
    }
}

const metas = (state = {}, action) => {
    switch (action.type) {
        case META_REQUEST:
        case META_SUCCESS:
        case META_FAILURE:
        case META_INVALIDATE:
            return { ...state, [action.loc]: meta(state[action.loc], action) };
        default:
            return state
    }
}

export default metas