import React from 'react';

import useAuthorization from '../hooks/useAuthorization';
import { useDispatch } from 'react-redux';
import { login } from '../actions/auth'

const SignedOutWelcome = () => {
    const auth = useAuthorization();
    const dispatch = useDispatch();

    if (auth.isInitializing || auth.isLoggedIn) return null;

    const onSignIn = (e) => {
        e.preventDefault();
        dispatch(login());
    }

    return (
        <div className='centering-container'>
            <h1 className='file-title'>Welcome! Please&nbsp;<button className='is-ahref' onClick={onSignIn}>Sign In</button>.</h1>
        </div>
    );
}

export default SignedOutWelcome;