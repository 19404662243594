import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { fetchIfNeeded } from '../actions/folders';

const useAuthorizedCollections = () => {
    const dispatch = useDispatch();

    const folders = useSelector(s => s.folders);

    useEffect(() => {dispatch(fetchIfNeeded())});
    
    return (folders.isFetching || !folders.isLoaded || folders.error)
        ? {
            areLoading: true,
            all: null,
            byKey: {},
            error: folders.error,
        }
        : {
            areLoading: false,
            all: folders.authorizedCollections,
            byKey: folders.authorizedCollections.reduce((a, c) => {
                a[c.key] = c;
                return a;
            }, {})
        };
}

export default useAuthorizedCollections;