import React from 'react'
import { connect } from 'react-redux'
import { login, logout } from '../actions/auth'
import { useHistory } from 'react-router-dom'

const Auth = (props) => {
    const history = useHistory();

    const onLoginClick = e => {
        e.preventDefault();
        props.login();
    }

    const onLogoutClick = e => {
        e.preventDefault();
        props.logout();
        history.push('/');
    }

    if (!props.isInitialized)
        return (
            <div className="navbar-item">
                <div className="buttons">
                    <span className="button is-light">Loading...</span>
                </div>
            </div>
        )

    if (props.isLoggedIn)
        return (
            <>
                <div className="navbar-item no-hover">
                    {props.loggedInAs}
                </div>
                <div className="navbar-item">
                    <button className="button is-light" onClick={onLogoutClick}>Sign Out</button>
                </div>
            </>
        )
    else
        return (
            <div className="navbar-item">
                <button className="button is-light" onClick={onLoginClick}>Sign In</button>
            </div>
        )
}

export default connect(s => s.auth, {
    login,
    logout
})(Auth)