import { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import * as qs from 'query-string';

import { fetchIfNeeded as fetchBodyIfNeeded } from '../actions/docContents';
import parseFile from '../util/parseFile';
import useMeta from '../hooks/useMeta';
import useDocuments from '../hooks/useDocuments';
import { formatDateTime } from '../util/date';
import { rewriteLinks } from '../util/rewriteLinks';

const useDocument = () => {

    const dispatch = useDispatch();
    const docContents = useSelector(s => s.docContents);
    const meta = useMeta();
    const documents = useDocuments();

    const history = useHistory();
    const location = useLocation();
    const parsed = qs.parse(location.search);

    const [docRaw, setDocRaw] = useState(null);
    const [docBody, setDocBody] = useState(null);
    const [docStyle, setDocStyle] = useState(null);

    // for debugging
    const showRaw = 'showRaw' in parsed ? true : false;

    const setContent = useCallback((content) => {
        if (!content) {
            setDocRaw(null);
            setDocBody(null);
            setDocStyle(null);
        }
        else {
            const parsed = parseFile(content, u => rewriteLinks(u, documents), history);
            setDocRaw(content);
            setDocBody(parsed.body);
            setDocStyle(parsed.style);
        }
    }, [history, documents.areLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

    const docContent = docContents[meta.id];

    const isLoadingDocContent = docContent && docContent.isFetching;

    const docTitle = (() => {
        if (meta.isLoading) return 'Loading...';
        if (!meta.id) return null; //'No Document Selected';
        return meta.name;
    })();

    const docBodyDisplay = (() => {
        if (isLoadingDocContent) return 'Loading...';
        if (!docBody) return null;
        return docBody;
    })();

    const fetchDocumentBodyIfNeeded = useCallback(() => {
        dispatch(fetchBodyIfNeeded(meta.id));
    }, [dispatch, meta.id])

    useEffect(() => {
        if (!meta.id) {
            setContent(null);
            return;
        }
        fetchDocumentBodyIfNeeded();
    }, [meta.id, setContent, fetchDocumentBodyIfNeeded]);

    useEffect(() => {
        setContent(docContent && docContent.body ? docContent.body : null);
    }, [setContent, docContent, isLoadingDocContent]);

    return {
        id: meta.id,
        title: docTitle,
        webViewLink: meta.webViewLink,
        canEdit: meta.canEdit,
        canReadRevisions: meta.canReadRevisions,
        modified: formatDateTime(meta.modifiedTime),
        style: docStyle,
        body: docBodyDisplay,
        raw: showRaw ? docRaw : null,
    };
}

export default useDocument;