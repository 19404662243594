import moment from 'moment';

export function formatDateTime(datetime) {
    if (!datetime) return null;
    const parsed = moment(datetime);
    parsed.local();
    return parsed.format('M/D/YY kk:mm');
}

export function dateDiffInSeconds(a, b = undefined) {
    if (!b) b = new Date();
    return Math.floor((b.valueOf() - a.valueOf()) / 1000);
}
