import config from '../config';
import { getUrl, getUrlQueryWord } from './urls';

const addUrl = (file) => {
    const url = getUrl(file.description);
    return url ? { ...file, url } : file;
}

export default class Google {
    static getClient = () => {
        if (window.gapi_client) return window.gapi_client;
        return window.gapi_client = new Promise((resolve, reject) => {
            window.gapi.load('client:auth2', () =>
                window.gapi.client.init(config.gapi)
                    .then(() => resolve(window.gapi.client))
            );
        });
    }

    static getAuth2 = () => {
        if (window.gapi_auth2) return window.gapi_auth2;
        return window.gapi_auth2 = new Promise((resolve, reject) => {
            Google.getClient().then(() => { resolve(window.gapi.auth2); })
        });
    }

    static getAuthInstance = async () => {
        const auth2 = await Google.getAuth2();
        return auth2.getAuthInstance();
    }

    static getFiles = async (options) => {
        if (!options.parentId && !options.parentIds) throw new Error(`Required parameter: 'parentId or parentIds'.`);
        if (!options.fields) options.fields = 'id, name, description, mimeType, webViewLink, parents, modifiedTime, capabilities';
        const client = await Google.getClient();
        let files = [];
        let nextPageToken = null;
        let q = options.parentId
            ? `trashed = false and '${options.parentId}' in parents`
            : `trashed = false and mimeType != 'application/vnd.google-apps.folder' and (${options.parentIds.map(id => `'${id}' in parents`).join(' or ')})`;
        if (options.query)
            q = `${q} and fullText contains '${options.query}'`;
        let requests = 0;
        do {
            requests++;
            let request = {
                corpora: config.scope.corpora,
                pageSize: 1000,
                fields: `nextPageToken, files(${options.fields})`,
                q,
                includeItemsFromAllDrives: true,
                supportsAllDrives: true
            };
            if (config.scope.driveId) request.driveId = config.scope.driveId;
            if (!options.query) request.orderBy = 'name';
            if (nextPageToken) request.pageToken = nextPageToken;
            let response = await client.drive.files.list(request);
            const exclude = config.flags.exclude;
            files = files.concat(response.result.files.filter(f => !f.description || !f.description.includes(exclude)));
            nextPageToken = response.result.nextPageToken;
        }
        while (nextPageToken)
        console.log(`read ${files.length} documents in ${requests} requests`);
        return files.map(addUrl);
    }

    static getRevisions = async (options) => {
        if (!options.id) throw new Error(`Required parameter: 'id'.`);
        if (!options.fields) options.fields = 'id, mimeType, modifiedTime, lastModifyingUser(displayName, emailAddress), size, exportLinks';
        const client = await Google.getClient();
        let revisions = [];
        let nextPageToken = null;
        let requests = 0;
        do {
            requests++;
            let request = {
                pageSize: 200,
                fileId: options.id,
                fields: `nextPageToken, revisions(${options.fields})`,
            };
            if (nextPageToken) request.pageToken = nextPageToken;
            let response = await client.drive.revisions.list(request);
            revisions = revisions.concat(response.result.revisions);
            nextPageToken = response.result.nextPageToken;
        }
        while (nextPageToken)
        console.log(`read ${revisions.length} revisions in ${requests} requests`);
        return revisions;
    }

    static getAllFolders = async (options = {}) => {
        if (!options.fields) options.fields = 'id, name, parents, capabilities';
        if (!options.pageSize) options.pageSize = 1000;
        const client = await Google.getClient();
        let folders = [];
        let nextPageToken = null;
        let requests = 0;
        do {
            requests++;
            // this is necessary until https://issuetracker.google.com/issues/111763024 is resolved
            const wikiFlag = config.flags.folder;
            let request = {
                corpora: config.scope.corpora,
                pageSize: options.pageSize,
                fields: `nextPageToken, files(${options.fields})`,
                q: `trashed = false and fullText contains '${wikiFlag}' and mimeType = 'application/vnd.google-apps.folder'`,
                includeItemsFromAllDrives: true,
                supportsAllDrives: true
            };
            if (config.scope.driveId) request.driveId = config.scope.driveId;
            if (nextPageToken) request.pageToken = nextPageToken;
            let response = await client.drive.files.list(request);
            folders = folders.concat(response.result.files);
            nextPageToken = response.result.nextPageToken;
        }
        while (nextPageToken)
        console.log(`read ${folders.length} folders in ${requests} requests`);
        return folders;
    }

    static getFile = async (options) => {
        if (!options.id && !options.loc) throw new Error(`Required parameter: 'id' or 'loc'.`);
        if (!options.fields) options.fields = 'id, name, description, mimeType, webViewLink, parents, modifiedTime, capabilities';
        try {
            const client = await Google.getClient();

            const lookupById = () => new Promise(async (res, rej) => {
                try {
                    res(await client.drive.files.get({
                        fileId: options.id ? options.id : options.loc,
                        fields: options.fields,
                        supportsAllDrives: true
                    }));
                } catch (err) {
                    rej(err)
                }
            }).catch(err => {
                if (err.status === 404)
                    return null;
                else
                    throw err;
            }).then(response =>
                response ? response.result : null
            );

            const lookupByAnchor = async () => {
                if (!options.loc)
                    return null;
                const query = getUrlQueryWord(options.loc);
                const client = await Google.getClient();
                let q = `trashed = false and fullText contains '${query}'`;
                let request = {
                    corpora: config.scope.corpora,
                    pageSize: 2,
                    fields: `files(${options.fields})`,
                    q,
                    includeItemsFromAllDrives: true,
                    supportsAllDrives: true
                };
                if (config.scope.driveId) request.driveId = config.scope.driveId;
                let response = await client.drive.files.list(request);
                const files = config.flags.exclude
                    ? response.result.files.filter(f => !f.description || !f.description.includes(config.flags.exclude))
                    : response.result.files;
                if (files.length !== 1) {
                    // anchor only works if it is absolutely unique among all docs
                    // TODO: better messaging when this happens
                    return null;
                }
                return files[0];
            }

            const [byId, byAnchor] = await Promise.all([lookupById(), lookupByAnchor()]);
            if (byId) return addUrl(byId);
            if (byAnchor) return addUrl(byAnchor);
            return null;
        }
        catch (error) {
            console.error(error);
            throw error;
        }
    }

    static getFileContent = async (options) => {
        if (!options.id) throw new Error(`Required parameter: 'id'.`);
        let mimeType = options.mimeType ? options.mimeType : (await Google.getFile({ id: options.id })).mimeType;

        const client = await Google.getClient();
        switch (mimeType) {
            case 'application/vnd.google-apps.document':
                const response = await client.drive.files.export({
                    fileId: options.id,
                    mimeType: "text/html"
                });
                return response.body;
            case 'application/vnd.google-apps.spreadsheet':
                return '<div>Google Sheets not supported (yet)</div>';
            default:
                return `<div>Unsupported file type '${mimeType}'</div>`;
        }
    }

    // procedure BFS(G, v) is
    // create a queue Q
    // enqueue v onto Q
    // mark v
    // while Q is not empty do
    //     w ← Q.dequeue()
    //     if w is what we are looking for then
    //         return w
    //     for all edges e in G.adjacentEdges(w) do
    //         x ← G.adjacentVertex(w, e)
    //         if x is not marked then
    //             mark x
    //             enqueue x onto Q
    // return null
    static findCollection = async (options) => {
        if (!options.id) throw new Error(`Required parameter: 'id'.`);
        if (!options.collections) throw new Error(`Required parameter: 'collections'.`);

        // short-circuit if the provided id is a collection folderId
        let collection = options.collections.find(c => c.id === options.id);
        if (collection) return collection;

        // perform a breadth-first search for the collection
        const fields = 'id, name, mimeType, parents';
        const file = await Google.getFile({ id: options.id, fields });
        const Q = [], marked = [];
        Q.push(file);
        marked[file.id] = true;
        while (Q.length > 0) {
            let w = Q.pop();
            collection = options.collections.find(c => w.parents.find(p => c.rootFolderId === p.id));
            if (collection) return collection;
            w.parents.forEach(async p => {
                if (!p || !p.id) return;
                let parent = await Google.getFile({ id: p.id, fields });
                if (!marked[parent.id]) {
                    marked[parent.id] = true;
                    Q.push(parent);
                }
            });
        }
        return null;
    }

    static getFolderTree = async (folderId) => {
        if (!folderId) throw new Error(`Required parameter: 'folderId'.`);

        const getChildren = async (id) => {
            const children = await Google.getFiles({ parentId: id });
            await Promise.all(children.map(async (c) => {
                if (c.mimeType === 'application/vnd.google-apps.folder')
                    c.children = await getChildren(c.id);
            }));
            return children;
        }

        return {
            id: folderId,
            mimeType: 'application/vnd.google-apps.folder',
            children: await getChildren(folderId)
        };
    }

    static createFromTemplate = async ({ template, title, description, folderId }) => {
        if (!template) throw new Error(`Required parameter: 'template'.`);
        const templateId = (() => {
            if (template === 'ref')
                return config.templates.ref;
            if (template === 'sop')
                return config.templates.sop;
            throw new Error(`Invalid value for 'template' (valid values are 'ref' and 'sop').`);
        })();
        if (!title) throw new Error(`Required parameter: 'title'.`);
        if (!folderId) throw new Error(`Required parameter: 'folderId'.`);

        const mimeType = 'application/vnd.google-apps.document';
        const client = await Google.getClient();
        const request = {
            fileId: templateId,
            resource: {
                name: title,
                description,
                mimeType,
                parents: [folderId],
            },
            supportsAllDrives: true,
        }
        const response = await client.drive.files.copy(request);
        if (response.status !== 200) {
            return {
                success: false,
                error: `Error creating the file, ${response}`,
            }
        }
        return {
            success: true,
            document: {
                id: response.result.id,
                name: title,
                description,
                mimeType,
                webViewLink: `https://docs.google.com/document/d/${response.result.id}/edit`,
                parents: [folderId],
                modifiedTime: new Date(),
                capabilities: {
                    canEdit: true,
                    canReadRevisions: true,
                }
            },
        }
    }

    static updateFile = async ({ id, changes }) => {
        if (!id) throw new Error(`Required parameter: 'id'.`);
        if (!changes) throw new Error(`Required parameter: 'changes'.`);

        const client = await Google.getClient();
        const request = {
            fileId: id,
            resource: changes,
            supportsAllDrives: true,
        }
        const response = await client.drive.files.update(request);
        if (response.status !== 200) {
            return {
                success: false,
                error: `Error updating the file, ${response}`,
            }
        }
        return { success: true }
    }

}
