import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames';

import { SIGNED_OUT, ERROR, NO_COLLECTION, LOADING } from '../containers/DocumentTreeFolder';

const DocumentNavBarFolder = forwardRef(({
    show, error, isRoot, link,
    currentFileName, folderName, isExpanded, children, onClick }, ref) => {
    const navbar = useRef();
    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        onSelect() {
            setIsOpen(false);
        }
    }));

    const handleClickOutside = e => {
        if (navbar.current.contains(e.target)) {
            // inside click
            return;
        }
        // outside click
        setIsOpen(false);
    };

    const onBurgerClick = e => {
        e.preventDefault();
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    let menuClass = classNames({
        'navbar-menu': true,
        //'document-menu': true,
        'is-active': isOpen
    });

    if (show === SIGNED_OUT || show === NO_COLLECTION)
        return (
            <div ref={navbar} className="navbar navbar-doc-title is-hidden-desktop" role="navigation">
                <div className="navbar-doc-banner navbar-item no-hover">
                    <div className='navbar-doc-title ellipsis'>&nbsp;</div>
                </div>
            </div>);

    const ShowRoot = (title, children) => (
        <div ref={navbar} className="navbar navbar-doc-title is-hidden-desktop" role="navigation">
            <div className="navbar-doc-banner navbar-item navbar-link" href="#" onClick={onBurgerClick}>
                <div className='navbar-doc-title ellipsis'>{title}</div>
            </div>
            <div className={menuClass}>
                <div className="navbar-start">
                    <ul className='menu-list'>{children}</ul>
                </div>
            </div>
        </div>)

    if (show === ERROR)
        return ShowRoot(error, null);

    if (show === LOADING)
        return ShowRoot(show === LOADING ? 'Loading...' : 'Choose A Collection', null);

    if (isRoot)
        return ShowRoot(currentFileName ? currentFileName : 'Select A Document', children);

    return (
        <li>
            <a className='menu-item' href={link} onClick={e => { e.preventDefault(); onClick(); }}>{folderName}</a>
            <ul className={classNames({ 'is-hidden': !isExpanded })}>{children}</ul>
        </li>
    );
})

export default DocumentNavBarFolder;
