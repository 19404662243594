import {
    FOLDERS_INVALIDATE, FOLDERS_REQUEST, FOLDERS_SUCCESS, FOLDERS_FAILURE,
} from '../actions/folders';

const initialState = {
    isFetching: false,
    didInvalidate: false,
    isLoaded: false,
    authorizedCollections: [], /* {
        key: string,
        name: string,
        shortName: string,
        rootFolderId: string,
        rootFolderName: string
        } */
    allIds: null, // [string]
    byId: null, /* id => {
        id: string,
        name: string,
        canAddChildren: boolean,
        collection: string} */
    byParentId: null, // { id => [string] }
    error: null // string
}

const folders = (state = initialState, action) => {
    switch (action.type) {
        case FOLDERS_REQUEST:
            return { ...state, isFetching: true };

        case FOLDERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                didInvalidate: false,
                isLoaded: true,
                authorizedCollections: action.authorizedCollections,
                allIds: action.allIds,
                byId: action.byId,
                byParentId: action.byParentId,
            }

        case FOLDERS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case FOLDERS_INVALIDATE:
            return {
                ...state,
                didInvalidate: true
            }
        default:
            return state
    }
}

export default folders