import React, { createContext, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { update as updateDocument } from '../actions/documents';
import useDocuments from '../hooks/useDocuments';
import useSelectedDocument from '../hooks/useSelectedDocument';
import useMeta from '../hooks/useMeta';
import Google from '../util/Google';
import Spinner from './Spinner';
import { refetchIfNeeded } from '../actions/documents';
import { toUrl, MAX_URL_LENGTH, PREFERRED_URL_CHARS, PREFERRED_URL_PATTERN, updateUrl, getUrl } from '../util/urls';

import '../style/EditDocument.scss';
import { SEARCH } from '../hooks/useNavigation';

const EditDocumentContext = createContext({});
export const EditDocumentProvider = ({ children }) => {
    const [showEditDocument, setShowEditDocument] = useState(false);
    const documents = useDocuments();
    const dispatch = useDispatch();

    const onEditDocumentClick = () => {
        // refresh our list of documents to make sure we've got the latest
        dispatch(refetchIfNeeded(60));
        setShowEditDocument(true);
    }

    const closeEditDocumentModal = () => {
        setShowEditDocument(false);
    }

    return <EditDocumentContext.Provider value={{
        onEditDocumentClick,
        showEditDocument,
        closeEditDocumentModal,
    }}>{children}</EditDocumentContext.Provider>
}

export const EditDocumentButton = () => {
    return (<EditDocumentContext.Consumer>{
        ({ onEditDocumentClick }) => (
            <button className='is-ahref doc-edit-link' onClick={onEditDocumentClick}>[Edit URL]</button>
        )
    }</EditDocumentContext.Consumer>)
}

const UrlInput = ({ value, setValue }) => {
    const collections = useSelector(s => s.collections);
    const documents = useDocuments();
    const meta = useMeta();
    const { selectedDocument } = useSelectedDocument();
    const [input, setInput] = useState(value ?? '');
    const [error, setError] = useState(null);
    useEffect(() => {
        const url = toUrl(value ?? meta.name);
        if (url != value)
            setInput(url);
            setValue(url);
    }, []);
    const validate = useCallback((newValue) => {
        setInput(newValue);
        if (!newValue || newValue.trim() === '') {
            setError(null);
            setValue(null);
        }
        else if (!PREFERRED_URL_PATTERN.test(newValue)) {
            setError(`Invalid input. Allowed characters are: ${PREFERRED_URL_CHARS}`);
            setValue(undefined);
        }
        else if (collections && collections.isLoaded && newValue in collections.byKey) {
            setError(`Value is reserved for a collection.`);
            setValue(undefined);
        }
        else if (newValue === SEARCH) {
            setError(`Value is reserved for search.`);
            setValue(undefined);
        }
        else if (documents.areLoaded && newValue in documents.byUrl && documents.byUrl[newValue][0] !== selectedDocument.id) {
            setError(`Already in use by another document.`);
            setValue(undefined);
        }
        else {
            setError(null);
            setValue(newValue);
        }
    }, [setValue, collections, documents.areLoaded, documents.byUrl, selectedDocument.id]);
    return (<React.Fragment>
        <span className="has-text-weight-bold">URL</span>&nbsp;
        <input
            type="text" value={input} maxLength={MAX_URL_LENGTH} size={MAX_URL_LENGTH}
            onChange={(e) => validate(e.target.value)}
        /><br />
        {error ? <span className="error">{error}</span> : null}
    </React.Fragment>);
}

const updateFileUrl = async ({ document, url }) => {
    if (!document) throw new Error(`Required parameter: 'id`);
    if (!url) throw new Error(`Required parameter: 'url`);
    if (url === getUrl(document.description))
        return { success: true, document }
    const newDescription = updateUrl(document.description, url);
    const result = await Google.updateFile({ id: document.id, changes: { description: newDescription } });
    if (!result.success)
        return result;
    return {
        success: true,
        document: {
            ...document,
            url,
            description: newDescription,
        }
    }
}

const EditDocument = ({ close }) => {
    const meta = useMeta();
    const documents = useDocuments();
    const [url, setUrl] = useState(meta.isLoading ? null : meta.url);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const document = documents.byId[meta.id];

    const onSaveClick = () => {
        // create document, close the popup, open doc in new tab
        setSaving(true);
        setError(null);

        updateFileUrl({ document, url }).then(result => {
            if (result.success) {
                dispatch(updateDocument(result.document));
                setSaving(false);
                close();
            } else {
                setSaving(false);
                setError(result.error);
            }
        }).catch((err) => {
            console.error(err);
            setSaving(false);
        })
    }

    return (<div>
        <div className="my-3">
            <UrlInput value={url} setValue={setUrl} />
        </div>
        <div className="level">
            <div className="level-item has-text-centered">
                <button className="is-size-5" disabled={saving || !document || url === undefined}
                    onClick={onSaveClick}>{saving ? 'Saving...' : 'Save'}</button>
            </div>
        </div>
        <div className="level">
            {saving ? <div className="level-item has-text-centered"><Spinner color="black" /></div> : null}
            {error ? <div className="level-item has-text-centered"><span className="error">{error}</span></div> : null}
        </div>
    </div>)
}

export const EditDocumentModal = () => {
    const documents = useDocuments();
    const meta = useMeta();
    const title = meta.isLoading ? 'Loading...' : meta.name;
    return (<EditDocumentContext.Consumer>{
        ({ showEditDocument, closeEditDocumentModal }) => {
            if (!showEditDocument) return null;
            return (
                <div className="modal is-active">
                    <div className="modal-background" onMouseDown={closeEditDocumentModal}></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title" style={{maxWidth: '580px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>Edit - {title}</p>
                            <button className="delete" aria-label="close" onClick={closeEditDocumentModal}></button>
                        </header>
                        <section className="modal-card-body">
                            {documents && !documents.areLoaded
                                ? <div className="level"><div className="level-item has-text-centered"><Spinner color="black" /></div></div>
                                : <EditDocument close={closeEditDocumentModal} />}
                        </section>
                    </div>
                </div>
            )
        }}
    </EditDocumentContext.Consumer>)
}
