import {
    DOCUMENTS_INVALIDATE, DOCUMENTS_REQUEST, DOCUMENTS_SUCCESS, DOCUMENTS_FAILURE, DOCUMENT_ADD, DOCUMENT_UPDATE
} from '../actions/documents';

const initialState = {
    isFetching: false,
    lastRefreshed: null, // datetime
    didInvalidate: false,
    isLoaded: false,
    byId: null, /* [{
        id: string
        name: string
        description: string
        mimeType: string
        webViewLink: string
        parentId: string
        modifiedTime: datetime
        canEdit: bool
        canReadRevisions: bool
    }] */
    byUrl: null, // { url => [id] }
    byParentId: null, // { parentId => [id] }
    error: null // string
}

const documents = (state = initialState, action) => {
    switch (action.type) {
        case DOCUMENTS_REQUEST:
            return { ...state, isFetching: true };
        case DOCUMENTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                didInvalidate: false,
                isLoaded: true,
                byId: action.byId,
                byUrl: getUrlMap(action.byId),
                byParentId: getParentMap(action.byId),
                lastRefreshed: action.lastRefreshed,
            }
        case DOCUMENTS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case DOCUMENTS_INVALIDATE:
            return {
                ...state,
                didInvalidate: true
            }
        case DOCUMENT_ADD:
            {
                const byId = { ...state.byId, [action.document.id]: action.document };
                const ids = [...state.byParentId[action.document.parentId], action.document.id];
                const currentUrlIds = state.byUrl[action.document.url] ? state.byUrl[action.document.url] : [];
                return {
                    ...state,
                    byId,
                    byUrl: action.document.url
                        ? {
                            ...state.byUrl,
                            [action.document.url]: [...currentUrlIds, action.document.id],
                        }
                        : state.byUrl,
                    byParentId: {
                        ...state.byParentId,
                        [action.document.parentId]: sortDocumentIds(ids, byId),
                    }
                }
            }
        case DOCUMENT_UPDATE:
            {
                const byId = { ...state.byId, [action.document.id]: action.document };
                const byUrl = { ...state.byUrl, [action.document.url]: [action.document.id] }; // deliberately leave the old url in place
                return {
                    ...state,
                    byId,
                    byUrl,
                    byParentId: getParentMap(byId),
                }
            }
        default:
            return state
    }
}

const getUrlMap = (byId) => Object.values(byId).reduce((a, f) => {
    if (!f.url) return a;
    if (!(f.url in a)) a[f.url] = [];
    a[f.url].push(f.id);
    return a;
}, {});

const getParentMap = (byId) => Object.fromEntries(Object.entries(Object.values(byId).reduce((a, f) => {
    if (!(f.parentId in a))
        a[f.parentId] = [];
    a[f.parentId].push(f.id);
    return a;
}, {})).map(([pid, ids]) => [pid, sortDocumentIds(ids, byId)]));

export const sortDocumentIds = (ids, byId) => {
    const sorted = [...ids];
    sorted.sort((a, b) => ('' + byId[a].name).localeCompare(byId[b].name));
    return sorted;
}

export default documents