import React, { useRef } from 'react';

import DocumentTreeFolder, { NO_COLLECTION, LOADING, SIGNED_OUT } from './DocumentTreeFolder';
import useSelectedDocument from '../hooks/useSelectedDocument';
import useSelectedCollection from '../hooks/useSelectedCollection';
import useFolders from '../hooks/useFolders';
import useDocuments from '../hooks/useDocuments';
import useAuthorization from '../hooks/useAuthorization';
import useMeta from '../hooks/useMeta';

const DocumentTree = ({ format }) => {
    const auth = useAuthorization();
    const folders = useFolders();
    const documents = useDocuments();

    const { selectedCollection } = useSelectedCollection();
    const { selectedDocument, selectDocument } = useSelectedDocument();
    const meta = useMeta();

    const rootRef = useRef();
    const onSelect = ({id, url}) => {
        selectDocument(url ? url : id);
        rootRef.current.onSelect();
    }

    if (auth.isLoggedOut || auth.isInitializing) {
        return <DocumentTreeFolder format={format} show={SIGNED_OUT} />;
    }

    if (!(selectedCollection.isLoading || selectedCollection.key)) {
        return <DocumentTreeFolder format={format} show={NO_COLLECTION} />;
    }

    if (selectedCollection.isLoading || folders.areLoading || !documents.areLoaded) {
        return <DocumentTreeFolder format={format} show={LOADING} />;
    }

    // if (error)
    //     return <DocumentTreeFolder format={format} showError error={error} />

    return (
        <DocumentTreeFolder isRoot ref={rootRef} format={format} onSelect={onSelect}
            folderId={selectedCollection.rootFolderId} currentFileName={meta.name}
            selectedCollectionKey={selectedCollection.key} selectedDocumentId={selectedDocument.id} 
            selectedCollectionLink={`/${selectedCollection.key}`} selectedCollectionName={selectedCollection.name}
            documents={documents} folders={folders} />
    );
}

export default DocumentTree;