import React from 'react';
import { Helmet } from 'react-helmet-async';
import { EditDocumentButton } from './EditDocument';
import useDocument from '../hooks/useDocument';
import useAuthorization from '../hooks/useAuthorization';
import '../style/DocumentViewer.scss';

const DocumentViewer = ({ onShowRevisions }) => {
    const auth = useAuthorization();
    const doc = useDocument();

    if (auth.isInitializing || auth.isLoggedOut || !doc.title) return null;

    return (
        <>
            <style type='text/css'>{doc.style}</style>
            <div className='file-container'>
                <div className='columns'>
                    <div className='column'>
                        <Helmet><title>{doc.title}</title></Helmet>
                        <h1 className="file-title columns">{doc.title}</h1>
                    </div>
                    {doc.webViewLink ? (
                        <div className='column is-narrow is-hidden-mobile'>
                            Last Modified:&nbsp;
                            {doc.canReadRevisions
                                ? <button className='is-ahref' onClick={(e) => { if (onShowRevisions) onShowRevisions() }}>{doc.modified}</button>
                                : <span>{doc.modified}</span>}
                            <br />
                            <a className='doc-edit-link' href={doc.webViewLink} target='_blank' rel='noopener noreferrer'>
                                [Open in GSuite]
                            </a>
                            {doc.canEdit
                                ? <React.Fragment>&nbsp;<EditDocumentButton /></React.Fragment>
                                : null}
                        </div>)
                        : null}
                </div>

                <div className='file-content document'>
                    {doc.body}
                </div>

                {/* debugging only */}
                {doc.raw ? (<div>
                    <pre>Raw:{"\n"}{doc.raw}{"\n"}{"\n"}</pre>
                    <pre>Style:{"\n"}{doc.style}{"\n"}{"\n"}</pre>
                </div>) : null}
            </div>
        </>
    );
}

export default DocumentViewer;