//import React from 'react'
import { useSelector } from 'react-redux'

const RequireAuthorization = ({children}) => {
    const auth = useSelector(s => s.auth);

    if (!auth.isInitialized || !auth.isLoggedIn) return null;
    return children;
}

export default RequireAuthorization;