import React, { useRef, useEffect, useState } from 'react';
import classNames from 'classnames';

import RequireAuthorization from '../containers/RequireAuthorization';
import useSelectedCollection from '../hooks/useSelectedCollection';
import useAuthorizedCollections from '../hooks/useAuthorizedCollections';
import Spinner from './Spinner';

const CollectionMenu = ({ onClick }) => {
    const { selectedCollection, selectCollection } = useSelectedCollection();
    const authorizedCollections = useAuthorizedCollections();

    const menu = useRef();
    const [isOpen, setIsOpen] = useState(false);

    const handleClickOutside = e => {
        if (menu.current.contains(e.target)) {
            return; // inside click
        }
        setIsOpen(false); // outside click
    };

    useEffect(() => {
        if (isOpen) document.addEventListener("mousedown", handleClickOutside);
        else document.removeEventListener("mousedown", handleClickOutside);
        return () => { document.removeEventListener("mousedown", handleClickOutside); };
    }, [isOpen]);

    const handleClick = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    const handleSelect = (key) => {
        setIsOpen(false);
        selectCollection(key); // changes the route
        if (onClick) onClick(); // closes the navbar (on mobile)
    }

    const renderCollections = (collections) =>
        collections.map(c =>
            <a key={c.key} href={`/${c.key}`} onClick={(e) => { e.preventDefault(); handleSelect(c.key); }}
                className={classNames({
                    'navbar-item': true,
                    'is-active': c.key === selectedCollection.key
                })}>{c.shortName}</a>);

    const render = (title, children) => (
        <div ref={menu} className={classNames({
            'navbar-item': true,
            'has-dropdown': true,
            'is-active': isOpen
        })}>
            <a className="navbar-link" href={`/${selectedCollection.key ?? ''}`} onClick={handleClick}>{title}</a>
            <div className={classNames({
                'navbar-dropdown': true,
                'is-hidden': !isOpen
            })}>{children}</div>
        </div>);

    if (authorizedCollections.error)
        return render('Error', 
            <div className='centering-container'>
                <p className="error center-text">
                    Error loading collections
                </p>
            </div>
        );

    if (authorizedCollections.areLoading || selectedCollection.isLoading) {
        return render('Loading...', <div className='centering-container'><Spinner /></div>);
    }
    if (!selectedCollection.key)
        return render('Select a Collection', renderCollections(authorizedCollections.all));
    else
        return render(selectedCollection.shortName, renderCollections(authorizedCollections.all));
}

const WrappedCollectionMenu = (props) => (
    <RequireAuthorization>
        <CollectionMenu {...props} />
    </RequireAuthorization>);

export default WrappedCollectionMenu;