import React, { createContext, useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import config from '../config';

import useFolders from '../hooks/useFolders';
import useDocuments from '../hooks/useDocuments';
import Google from '../util/Google';
import { add as addDocument } from '../actions/documents';
import { toUrl, updateUrl } from '../util/urls';
import Spinner from './Spinner';
import generateHtmlForCreatePage from '../style/create-page';
import { refetchIfNeeded } from '../actions/documents';

import '../style/AddDocument.scss';

const SOP_REGEX = /^\s*(SOP\s*#?\s*)?(?<sop>\d+).*$/i;
const SOP_TITLE_REGEX = /(how(\s|-)to)|(process)/i;
const SOP_TITLE_WARN = `Avoid redundant words like 'How To' or 'Process'.`;
const MAX_TITLE_LENGTH = 150;

const SOP_INFO = (<div className="content">
    <p>Come up with a title for your SOP that briefly describes the procedure it outlines. This will normally be in the form “Noun(s) Verb” where “Noun(s)” is the thing that the procedure is about and “Verb” describes the procedure. Avoid redundant works like “How To” or “Process”.</p>
    <p className="has-text-weight-bold">Examples:</p>
    <ul className="list">
        <li className="list-item"><span className="bad-example">Bad:</span>&nbsp;<span className="is-italic">“How to create a new unit in Core”</span></li>
        <li className="list-item"><span className="bad-example">Bad:</span>&nbsp;<span className="is-italic">“Sales order write-up process”</span></li>
        <li className="list-item"><span className="good-example">Good:</span>&nbsp;<span className="is-italic">“New Unit Entry”</span></li>
        <li className="list-item"><span className="good-example">Good:</span>&nbsp;<span className="is-italic">“Sales Order Write-up”</span></li>
    </ul>
</div>);

const REF_INFO = (<div className="content">
    <p>Come up with a title for your document that briefly describes what it does or documents.</p>
    <p className="has-text-weight-bold">Examples:</p>
    <ul className="list">
        <li className="list-item"><span className="is-italic">“How to do a Megger (Insulation Resistance) Test”</span></li>
        <li className="list-item"><span className="is-italic">“Bolt Torque Standards”</span></li>
        <li className="list-item"><span className="is-italic">“Recommended Pipedrive Settings”</span></li>
    </ul>
</div>);

const DOC_TYPE_INFO = (<div className="content">
    <p><span className="has-text-weight-bold">Standard Operating Procedures (SOPs):</span> procedure steps and/or checklists</p>
    <p>SOPs can be thought of similarly to the checklists that pilots use: they are always followed (even by experts) to ensure that no important steps are missed. They serve as a safeguard against lapses in memory or attention.</p>
    <p>SOPs should be concise and are expected to be referenced frequently by anyone performing the corresponding procedure.</p>
    <p>Detailed information for how to perform a step or specifications that may need to be referenced in a step should be documented in a corresponding Reference container and linked to in the SOP.</p>
    <p><span className="has-text-weight-bold">Reference:</span> “how it works”, and anything unrelated to a specific procedure</p>
    <p>Reference documents can be thought of similarly to the training or operator manuals for an aircraft: they are not frequently referenced by experienced pilots. They serve as an alternative to in-person / verbal instruction and as an alternative to human memory for obscure information.</p>
    <p>In general, these types of documents should be referenced infrequently by anyone experienced with a related procedure. If a document is (or should be) referenced frequently, consider whether it should be an SOP instead.</p>
    <p>SOPs can (and often should) link to reference documents but should not contain that information directly for the sake of brevity.</p>
</div>);

const AddDocumentContext = createContext({});
export const AddDocumentProvider = ({ children }) => {
    const [showAddDocument, setShowAddDocument] = useState(false);
    const [folderId, setFolderId] = useState(null);
    const folders = useFolders();
    const documents = useDocuments();
    const dispatch = useDispatch();

    const onAddDocumentClick = (folderId) => {
        // refresh our list of documents to make sure we've got the latest
        dispatch(refetchIfNeeded(60));
        setShowAddDocument(true);
        setFolderId(folderId);
    }

    const closeAddDocumentModal = () => {
        setShowAddDocument(false);
        setFolderId(null);
    }

    const hasAddPermissions = (folderId) => {
        if (!folderId)
            return null;
        if (folders.areLoading)
            return null;
        if (!(folderId in folders.byId))
            throw new Error(`Folder with id '${folderId}' not found`);
        return folders.byId[folderId].canAddChildren;
    }

    const getFolderName = (folderId) => {
        if (!folderId)
            return null;
        if (folders.areLoading)
            throw new Error(`Folders accessed prior to load`);
        if (!(folderId in folders.byId))
            throw new Error(`Folder with id '${folderId}' not found`);
        return folders.byId[folderId].name;
    }

    const getFolderCollection = (folderId) => {
        if (!folderId)
            return null;
        if (folders.areLoading)
            throw new Error(`Folders accessed prior to load`);
        if (!(folderId in folders.byId))
            throw new Error(`Folder with id '${folderId}' not found`);
        return folders.byId[folderId].collection;
    }

    const getUsedIdentifiers = useCallback(() => {
        if (!documents.areLoaded)
            throw new Error(`Documents accessed prior to load`);
        // const max_name = Object.entries(documents.byId).reduce((max, [id, doc]) => {
        //     if (doc.name.length > max.length)
        //         return doc.name;
        //     return max;
        // }, '');
        // console.log(`Max doc name length: (${max_name.length}) '${max_name}'`);
        const sop_numbers = Object.values(documents.byId).reduce((sop_nums, doc) => {
            const match = SOP_REGEX.exec(doc.name);
            if (match) sop_nums.push(parseInt(match.groups.sop));
            return sop_nums;
        }, []);
        return {
            sop_numbers,
            names: [...new Set(Object.values(documents.byId).map(d => d.name))],
            urls: Object.keys(documents.byUrl),
        };
    }, [documents]);

    return <AddDocumentContext.Provider value={{
        onAddDocumentClick,
        showAddDocument,
        closeAddDocumentModal,
        folderId,
        folderName: getFolderName(folderId),
        folderCollection: getFolderCollection(folderId),
        getUsedIdentifiers,
        hasAddPermissions,
    }}>{children}</AddDocumentContext.Provider>
}

export const AddDocumentButton = ({ folderId }) => {
    if (!folderId)
        throw new Error(`Prop 'folderId' is required`);
    return (<AddDocumentContext.Consumer>{
        ({ onAddDocumentClick }) => (
            <button className="level-right is-menu-item" onClick={e => { onAddDocumentClick(folderId); }}>
                <i className="plus-icon"></i>
            </button>
        )
    }</AddDocumentContext.Consumer>)
}

const Information = ({ title, children }) => {
    const [open, setOpen] = useState(false);
    return (<div><button className="info-link" onClick={() => setOpen(!open)}>{open ? 'Hide...' : title}</button>
        {open ? <div className="info-box">{children}</div> : null}
    </div>);
}

const DocumentTypeSelect = ({ docType, onDocTypeChange }) => {
    const handleChange = (e) => onDocTypeChange(e.target.value);
    const Option = ({ value, text }) => (<p>
        <input type="radio" value={value} id={value} onChange={handleChange} name={value} checked={value === docType} />&nbsp;
        <label htmlFor={value}>{text}</label>
    </p>)
    return (<React.Fragment>
        <span className="has-text-weight-bold">Document Type</span>
        <form>
            <Option value="ref" text="Reference Document" />
            <Option value="sop" text="Standard Operating Procedure (SOP)" />
        </form>
    </React.Fragment>);
}

const TitleInput = ({ value, setValue, usedValues, docType }) => {
    const [input, setInput] = useState(value ?? '');
    const [error, setError] = useState(null);
    const validate = useCallback((newValue) => {
        setInput(newValue);
        if (!newValue || newValue.trim() === '') {
            setError(`A value must be provided.`);
            setValue(null);
        }
        else if (docType === 'ref' && usedValues.includes(newValue)) {
            setError(`That title is already taken.`);
            setValue(null);
        }
        else if (docType === 'sop' && SOP_TITLE_REGEX.test(newValue)) {
            setError(SOP_TITLE_WARN);
            setValue(newValue);
        }
        else {
            setError(null);
            setValue(newValue);
        }
    }, [docType, setValue, usedValues]);
    useEffect(() => {
        if (value === '')
            validate(value);
    }, [value, validate]);
    useEffect(() => {
        if (input !== '')
            validate(input);
    }, [input, docType, validate]);
    return (<React.Fragment>
        <span className="has-text-weight-bold">Title</span>&nbsp;
        <input
            type="text" value={input} maxLength={MAX_TITLE_LENGTH} size="75"
            onChange={(e) => validate(e.target.value)}
        /><br />
        {error ? <span className="error">{error}</span> : null}
    </React.Fragment>);
}

const SopNumberInput = ({ value, setValue, usedValues, freeValue }) => {
    const [initial, setInitial] = useState(false);
    const [input, setInput] = useState(value ?? '');
    const [error, setError] = useState(null);
    const validate = useCallback((newValue) => {
        if (newValue === null || newValue === '') {
            setInput('');
            setError(`Value is required`);
            setValue(null);
            return;
        }
        setInput(newValue ?? '');
        const i = parseInt(newValue);
        if (Number.isNaN(i) || (i <= 0)) {
            setError(`Value must be an integer greater than zero`);
            setValue(null);
        }
        else if (usedValues.find(v => v === i)) {
            setError(`Value taken (but ${freeValue} is free)`);
            setValue(null);
        }
        else {
            setError(null);
            setValue(i);
        }
    }, [freeValue, usedValues, setValue]);
    useEffect(() => {
        if (freeValue !== null && !initial) {
            validate(freeValue);
            setInitial(true);
        }
    }, [freeValue, initial, validate])
    return (<React.Fragment>
        <span className="has-text-weight-bold">SOP Number</span>&nbsp;
        <input
            type="text" value={input} size="4" maxLength="4"
            onKeyPress={(e) => { if (!/[0-9]/.test(e.key)) { e.preventDefault(); } }}
            onChange={(e) => validate(e.target.value)}
        />
        {error ? <React.Fragment>&nbsp;<span className="error">{error}</span></React.Fragment> : null}
    </React.Fragment>);
}

const NewDocument = ({ folderId, folderCollection, getUsedIdentifiers, close }) => {
    const [docType, setDocType] = useState('ref');
    const [title, setTitle] = useState(null);
    const [sopNumber, setSopNumber] = useState(null);
    const [usedIdentifiers, setUsedIdentifiers] = useState(null);
    const [freeSopNumber, setFreeSopNumber] = useState(null);
    const [creating, setCreating] = useState(false);
    const [error, setError] = useState(null);
    const documents = useDocuments();
    const dispatch = useDispatch();

    useEffect(() => {
        // console.log(`Loading used SOP numbers...`)
        const used = getUsedIdentifiers();
        const free = Math.max(...[0, ...used.sop_numbers]) + 1;
        setUsedIdentifiers(used);
        setFreeSopNumber(free);
    }, [folderId, sopNumber, getUsedIdentifiers, documents.lastRefreshed]);

    const onCreateClick = () => {
        if (title === null || title.trim() === '')
            setTitle(''); // this will display the validation error
        else if (docType === 'sop' && (!sopNumber || sopNumber <= 0)) {
            // sop number is required
        }
        else {
            // create document, close the popup, open doc in new tab
            setCreating(true);
            setError(null);
            const fileTitle = docType === 'sop'
                ? `SOP # ${sopNumber} ${title}`
                : title;

            const base_url = docType === 'sop'
                ? `sop-${sopNumber}`
                : toUrl(title);
            let url = base_url;
            let n = 2;
            while (usedIdentifiers.urls.includes(url)) {
                url = `${base_url}_${n}`;
            }
            const description = updateUrl(null, url);

            var editWindow = window.open();
            editWindow.document.write(generateHtmlForCreatePage(fileTitle));
            Google.createFromTemplate({ template: docType, title: fileTitle, description, folderId }).then(result => {
                if (result.success) {
                    const doc = {
                        ...result.document,
                        parentId: result.document.parents[0],
                        url,
                        description,
                        collection: folderCollection,
                        canEdit: result.document.capabilities.canEdit,
                        canReadRevision: result.document.capabilities.canReadRevisions,
                    };
                    dispatch(addDocument(doc));
                    editWindow.location.href = doc.webViewLink;
                    setCreating(false);
                    close();
                } else {
                    setCreating(false);
                    setError(result.error);
                }
            }).catch((err) => {
                console.error(err);
                setCreating(false);
            })
        }
    }

    return (<div>
        <div>
            <DocumentTypeSelect docType={docType} onDocTypeChange={setDocType} />
            <Information title="What is the difference?">{DOC_TYPE_INFO}</Information>
        </div>
        {docType === 'sop'
            ? <div className="my-3"><SopNumberInput value={sopNumber} setValue={setSopNumber}
                usedValues={usedIdentifiers.sop_numbers} freeValue={freeSopNumber} />
            </div> : null}
        <div className="my-3">
            <TitleInput value={title} setValue={setTitle} usedValues={usedIdentifiers ? usedIdentifiers.names : []} docType={docType} />
            <Information title="What is a good title?">{docType === 'sop' ? SOP_INFO : REF_INFO}</Information>
        </div>
        <div className="level">
            <div className="level-item has-text-centered">
                <button className="is-size-5" disabled={creating}
                    onClick={onCreateClick}>{creating ? 'Creating...' : 'Create Document'}</button>
            </div>
        </div>
        <div className="level">
            {creating ? <div className="level-item has-text-centered"><Spinner color="black" /></div> : null}
            {error ? <div className="level-item has-text-centered"><span className="error">{error}</span></div> : null}
        </div>
    </div>)
}

const RequestNewDocument = ({ folderId, folderCollection, folderName, close }) => {
    const collection = (() => {
        if (folderCollection === 'sga')
            return 'Sales,+General,+or+Administrative+(SG%26A)';
        if (folderCollection === 'shop')
            return 'Production+(Shop)';
        return ''
    })();
    const url = config.newPageUrl
        .replace('<PAGE_TYPE>', collection)
        .replace('<FOLDER_URL>', `https://drive.google.com/drive/folders/${folderId}`)

    return (<div>
        <div>
            <p>
                You do not have sufficient permissions to add a document to the folder '{folderName}'.
                Instead, you can request one using the form below.<br />
                <a href={url} target='_blank' rel='noopener noreferrer'>
                    Request a New Wiki Page
                </a>
                <br /><br />
            </p>
        </div>
        <div className="level">
            <div className="level-item has-text-centered">
                <button className="is-size-5" onClick={close}>Close</button>
            </div>
        </div>
    </div>);
}

export const AddDocumentModal = () => (
    <AddDocumentContext.Consumer>{
        ({ showAddDocument, folderId, folderName, folderCollection, getUsedIdentifiers, closeAddDocumentModal, hasAddPermissions }) => {
            if (!showAddDocument) return null;
            const child = (() => {
                if (hasAddPermissions(folderId) === null)
                    return 'Loading...';
                if (hasAddPermissions(folderId) === false)
                    return (<RequestNewDocument folderId={folderId} folderName={folderName}
                        folderCollection={folderCollection}
                        close={() => closeAddDocumentModal()} />);
                return (<NewDocument folderId={folderId} folderName={folderName} folderCollection={folderCollection}
                    getUsedIdentifiers={getUsedIdentifiers}
                    close={() => closeAddDocumentModal()} />);
            })();

            return (
                <div className="modal is-active">
                    <div className="modal-background" onMouseDown={closeAddDocumentModal}></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">Add Document - {folderName}</p>
                            <button className="delete" aria-label="close" onClick={closeAddDocumentModal}></button>
                        </header>
                        <section className="modal-card-body">{child}</section>
                    </div>
                </div>
            )
        }
    }</AddDocumentContext.Consumer>
)
