import {
    FOLDER_TOGGLE, FOLDER_OPEN_MANY
} from '../actions/treeState';

const initialState = {}

const treeState = (state = initialState, action) => {
    switch (action.type) {
        case FOLDER_TOGGLE:
            return { ...state, [action.folderId]: !state[action.folderId] };
        case FOLDER_OPEN_MANY:
            const newState = {...state};
            action.folderIds.forEach(f => newState[f] = true);
            return newState;
        default:
            return state
    }
}

export default treeState
