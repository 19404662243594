const generateHtmlForCreatePage = (title) => `<html>

<head>
    <style>
        .main {
            height: 100vh;
        }

        .is-bold {
            font-weight: bold;
        }

        .title {
            font-family: system-ui, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif, BlinkMacSystemFont,-apple-system,"Segoe UI";
            font-size: 1.5em;
            text-align: center;
            margin-top: 0.5em;
            margin-bottom: 0.5em;
        }

        .container {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .wrapper {
            display: flex;
            flex-direction: column;
        }

        .spinner {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
        }

        .spinner div {
            display: inline-block;
            position: absolute;
            left: 8px;
            width: 16px;
            background: black;
            animation: spinner 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
        }

        .spinner div:nth-child(1) {
            left: 8px;
            animation-delay: -0.24s;
        }

        .spinner div:nth-child(2) {
            left: 32px;
            animation-delay: -0.12s;
        }

        .spinner div:nth-child(3) {
            left: 56px;
            animation-delay: 0;
        }

        @keyframes spinner {
            0% {
                top: 8px;
                height: 64px;
            }

            50%,
            100% {
                top: 24px;
                height: 32px;
            }
        }
    </style>
</head>

<body>
    <div class="main container">
        <div class="wrapper">
            <div>
                <p class="title">Creating New Document:</p>
                <p class="title is-bold">${title}</p>
                <p class="title">Please Wait...</p>
            </div>
            <div class="container">
                <div class="spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>

    </div>
</body>

</html>`;

export default generateHtmlForCreatePage;